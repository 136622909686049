import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutRoutingModule } from './layout-routing.module';
import { SharedModule } from '../dashboard/shared/shared.module';

import { SubscriptionsModule } from '../subscriptions/subscriptions.module';
import { LayoutComponent } from './layout.component';
import { AppSearchOverlayComponent } from './search-overlay/search-overlay.component';
import { AppFooterComponent } from './footer/footer.component';
import { AppCustomizerComponent } from './customizer/customizer.component';
import { AppSidenavMenuComponent } from './sidenav/sidenav-menu/sidenav-menu.component';
import { AppSidenavComponent } from './sidenav/sidenav.component';
import { AppHeaderComponent } from './header/header.component';
import { ToggleOffcanvasNavDirective } from './sidenav/toggle-offcanvas-nav.directive';
import { AutoCloseMobileNavDirective } from './sidenav/auto-close-mobile-nav.directive';
import { AccordionNavDirective } from './sidenav/sidenav-menu/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './sidenav/sidenav-menu/append-submenu-icon.directive';
import { OpenSearchOverlaylDirective } from './search-overlay/open-search-overlay.directive';
import { PreloaderDirective } from './preloader.directive';
import { ToggleQuickviewDirective } from './customizer/toggle-quickview.directive';
import { SearchOverlayDirective } from './search-overlay/search-overlay.directive';
import { SurveysModule } from '../surveys/surveys.module';
import { PipesModule } from '../pipes/pipes.module';
import { PatientsListComponent } from '../patients-list/patients-list.component';
import { CPPComponent } from '../cpp/cpp.component';
import { MembersComponent } from '../members/members.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';

import { InsigMaterialModule } from '../insig-material/insig-material.module';
import { InsigNavigationModule } from '../global/navigation/insig-navigation.module';

import { SelectAppointmentSurveyDialogComponent } from 'insig-app/global/virtual/settings/dialogs/select-appointment-survey.component';

// Libs
import { UiModule } from '@insig-health/ui';
import { FamilyDoctorSearchModule } from '@insig-health/family-doctor-search/family-doctor-search.module';
import { OnScrollToBottomDirective } from 'insig-app/layout/directives/on-scroll-to-bottom/on-scroll-to-bottom.directive';
import { PhoneNumberInputDirective } from 'insig-app/directives/phone-number-input.directive';
import { UiComponentsModule } from '@insig-health/components';

@NgModule({
  imports: [
    LayoutRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SurveysModule,
    SharedModule,
    CommonModule,
    MaterialComponentsModule,
    InsigMaterialModule,
    InsigNavigationModule,
    DashboardModule,
    PipesModule,
    SubscriptionsModule,
    UiModule,
    UiComponentsModule,
    InputMasksModule,
    FamilyDoctorSearchModule,
    PhoneNumberInputDirective,
  ],
  declarations: [
    // Components
    LayoutComponent,
    AppSearchOverlayComponent,
    AppFooterComponent,
    AppCustomizerComponent,
    AppSidenavMenuComponent,
    AppSidenavComponent,
    AppHeaderComponent,
    PatientsListComponent,
    CPPComponent,
    MembersComponent,
    // Directives
    AccordionNavDirective,
    AppendSubmenuIconDirective,
    AutoCloseMobileNavDirective,
    ToggleOffcanvasNavDirective,
    OpenSearchOverlaylDirective,
    PreloaderDirective,
    ToggleQuickviewDirective,
    SearchOverlayDirective,
    SelectAppointmentSurveyDialogComponent,
    OnScrollToBottomDirective,
  ],
})
export class LayoutModule { }
