<mat-card class="p-3">
  <mat-progress-spinner *ngIf="weeklyLoading" class="mx-auto" mode="indeterminate"></mat-progress-spinner>

  <ng-container *ngIf="!weeklyLoading">
    <div class="d-flex justify-content-end">
      <button mat-raised-button class="primary-color-full float-right" (click)="saveFullSchedule()" [disabled]="isSavingSchedule">
        Save Availability
      </button>
    </div>

    <div *ngIf="!showCalendar && !showTimes">
      <mat-form-field>
        <mat-label>Set default availability</mat-label>
        <mat-select [(ngModel)]="weeklySchedule.defaultAvailability">
          <mat-option [value]="'both'">Clinic and Virtual Care</mat-option>
          <mat-option [value]="'virtual'">Virtual Care Only</mat-option>
          <mat-option [value]="'clinic'">In Clinic Only</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="py-2"></div>

      <!-- #region week view -->
      <mat-grid-list [cols]="7" rowHeight="60px" class="full-width">
        <mat-grid-tile *ngFor="let weekday of daysOfTheWeek; index as index" [colspan]="1">
          <div class="d-flex flex-column align-items-center">
            <button mat-raised-button (click)="editWeekdaySchedule(weekday)">
              {{ weekday.charAt(0).toUpperCase() + weekday.slice(1) }}
            </button>
            <span class="py-2">{{
              getTimeZoneAbbr(weeklySchedule[weekday], index)
              }}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list [cols]="7" [rowHeight]="dynamicHeight()" class="full-width">
        <mat-grid-tile *ngFor="let weekday of daysOfTheWeek" [colspan]="1">
          <div class="h-100 pt-3 d-flex flex-column justify-content-start gap-3">
            <div *ngFor="let segment of weeklySchedule[weekday].timeSegments">
              <div>{{ segment.availability | insAppointmentType }}</div>
              <div>from: {{ doctorSettingsService.convertMsPastMidnightToTimeString(segment.operatingStartTime) }}</div>
              <div>to: {{ doctorSettingsService.convertMsPastMidnightToTimeString(segment.operatingEndTime) }}</div>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- #endregion week view -->
    </div>
  </ng-container>

  <div *ngIf="!weeklyLoading">
    <!-- #region calendar month view -->
    <ng-container *ngIf="showCalendar">
      <ng-container *ngIf="!showTimes">
        <div class="mx-auto">
          <!-- #region header -->
          <div class="w-100 py-4 d-flex">
            <!-- #region left header items -->
            <div class="flex-equal">
              <button mat-raised-button class="primary-color-full" (click)="showCalendar = false">
                <mat-icon class="notranslate">keyboard_backspace</mat-icon>
                <span>&nbsp;Back</span>
              </button>
            </div>
            <!-- #endregion left header items -->

            <!-- #region center header items -->
            <div class="flex-equal d-flex">
              <span class="mx-auto d-flex align-items-center">
                <button mat-icon-button (click)="goToPreviousMonth()">
                  <i class="fa fa-caret-left" aria-hidden="true"></i>
                </button>
                <span class="text-center"><b>{{
                    viewDate | calendarDate: 'month' + 'ViewTitle':'en'
                    }}</b></span>
                <button mat-icon-button (click)="goToNextMonth()">
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                </button>
              </span>
            </div>
            <!-- #endregion center header items -->

            <!-- #region right header items -->
            <div class="flex-equal">
              <!-- placeholder -->
            </div>
            <!-- #endregion right header items -->
          </div>
          <!-- #endregion header -->

          <!-- #region calendar view -->
          <div class="availability-calendar">
            <mwl-calendar-month-view [(viewDate)]="viewDate" (updateViewDate)="updateViewDate($event)" [events]="events"
              [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event)"
              (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="doctorSettingsCellTemplate"
              [headerTemplate]="doctorSettingsHeaderTemplate">
            </mwl-calendar-month-view>
          </div>
          <!-- #endregion calendar view -->
        </div>
      </ng-container>

      <ng-container *ngIf="showTimes">
        <daily-schedule [exceptionDate]="exceptionDate" [schedule]="exceptionSchedule[exceptionDate.toDateString()]"
          (scheduleChange)="setExceptionDate(exceptionDate, $event)" [locations]="locations"
          [defaultAvailability]="weeklySchedule.defaultAvailability" (backClicked)="showTimes = false"></daily-schedule>
      </ng-container>
    </ng-container>
    <!-- #endregion calendar month view -->

    <!-- #region calendar day view -->
    <daily-schedule *ngIf="!!selectedWeekday" [selectedWeekday]="selectedWeekday"
      [(schedule)]="weeklySchedule[selectedWeekday]" [locations]="locations"
      [defaultAvailability]="weeklySchedule.defaultAvailability" (backClicked)="backToCalendar()"></daily-schedule>
    <!-- #endregion calendar day view -->
  </div>

  <ng-container *ngIf="!showCalendar && !this.selectedWeekday">
    <div class="py-3"></div>

    <div class="w-100 d-flex justify-content-center">
      <button mat-raised-button [ngClass]="{
          'primary-color-full': !exceptionsLoading && !appointmentsLoading
        }" (click)="showCalendar = true" [disabled]="exceptionsLoading || appointmentsLoading">
        <div class="d-flex flex-row align-items-center">
          <mat-spinner [diameter]="20" *ngIf="exceptionsLoading || appointmentsLoading"></mat-spinner>
          <span>{{
            exceptionsLoading || appointmentsLoading
            ? '&nbsp;Loading Your Schedule'
            : 'Make exceptions for
            specific dates'
            }}</span>
        </div>
      </button>
    </div>
  </ng-container>
</mat-card>

<!-- #region custom cell template -->
<ng-template #doctorSettingsCellTemplate let-day="day">
  <div [style.backgroundColor]="
      day.isPast
        ? 'none'
        : getAppointmentSlotCountForDay(day.date) > 0
        ? 'rgba(128,255,128,0.1)'
        : 'rgba(255,128,128,0.1)'
    ">
    <span class="p-1 float-right">
      <span *ngIf="day.date.toISOString() === viewDate.toISOString()" class="w-100 cal-day-number notranslate">
        <b>{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</b>
      </span>
      <span *ngIf="day.date.toISOString() !== viewDate.toISOString()" class="w-100 cal-day-number notranslate">
        {{ day.date | calendarDate: 'monthViewDayNumber':locale }}
      </span>
    </span>
    <span *ngIf="hasExceptionOn(day.date)" class="p-1 float-left" matTooltip="This day has an exception">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </span>
    <span *ngIf="hasAppointmentOn(day.date)" class="p-1 float-left"
      matTooltip="This day has an appointment booked already">
      <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
    </span>
  </div>
</ng-template>
<!-- #endregion custom cell template -->

<!-- #region custom header template -->
<ng-template #doctorSettingsHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
  <div class="cal-day-headers d-flex w-100">
    <div class="cal-header flex-col" *ngFor="let day of days" (mwlClick)="dayHeaderClicked.emit({ day: day })">
      {{ day.date | calendarDate: 'weekViewColumnHeader':locale }}
    </div>
  </div>
</ng-template>
<!-- #endregion custom header template -->
