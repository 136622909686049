import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import { INSIG_BOOKING_FIREBASE_CONFIG, INSIG_CORE_FIREBASE_CONFIG } from './config';

export const FIREBASE_ANALYTICS_APP_NAME = 'analytics';

export function initializeCoreFirebaseApp(): firebase.app.App {
  return firebase.initializeApp(INSIG_CORE_FIREBASE_CONFIG);
}

export function initializeBookingFirebaseAnalyticsApp(): firebase.app.App {
  return firebase.initializeApp(INSIG_BOOKING_FIREBASE_CONFIG, FIREBASE_ANALYTICS_APP_NAME);
}
