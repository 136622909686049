import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, DoCheck, ElementRef, HostBinding, inject, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Iti } from 'intl-tel-input';
import intlTelInput from 'intl-tel-input/intlTelInputWithUtils';
import { Subject, Subscription, BehaviorSubject, map, startWith, filter } from 'rxjs';

@Component({
  selector: 'insig-ui-phone-number-input',
  styleUrls: ['./insig-phone-number-input.component.scss'],
  templateUrl: './insig-phone-number-input.component.html',
  providers: [
    { provide: MatFormFieldControl, useExisting: InsigPhoneNumberInputComponent },
  ],
})
export class InsigPhoneNumberInputComponent implements AfterViewInit, ControlValueAccessor, DoCheck, MatFormFieldControl<string>, OnDestroy {
  public static nextId = 0;

  public readonly elementRef = inject(ElementRef);
  public ngControl = inject(NgControl, { optional: true, self: true });
  public parentFormField = inject(MatFormField, { optional: true });
  private readonly _parentForm = inject(NgForm, { optional: true });
  private readonly _parentFormGroup = inject(FormGroupDirective, { optional: true });

  @HostBinding() id = `insig-phone-number-input-${InsigPhoneNumberInputComponent.nextId++}`;

  @ViewChildren('inputElement')
  public inputElementQueryList: QueryList<ElementRef> | undefined;
  public inputElement: ElementRef | undefined;

  public inputElementQueryListSubscription: Subscription | undefined;

  public phoneInput: Iti | undefined;

  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  public stateChanges = new Subject<void>();
  private _errorState = false;
  get errorState(): boolean {
    return this._errorState;
  }
  set errorState(value: boolean) {
    this._errorState = !!value;
  }

  public touched = false;

  private _isReadOnly = false;
  @Input()
  get isReadOnly(): boolean {
    return this._isReadOnly;
  }
  set isReadOnly(isReadOnly: boolean | 'true' | 'false') {
    this._isReadOnly = coerceBooleanProperty(isReadOnly);
  }

  @Input() containerElement: HTMLElement | null = null;
  @Input() label = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  controlType = 'insig-phone-number-input';
  public focused = false;

  public readonly value$ = new BehaviorSubject<string>('');
  private valueSubscription: Subscription | undefined;
  @Input()
  get value(): string {
    return this.phoneInput?.getNumber() ?? '';
  }
  set value(value: string | null) {
    if (value !== null) {
      this.value$.next(value);
      this.stateChanges.next();
    }
  }

  private _required = false;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input('aria-describedby') userAriaDescribedBy = '';
  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elementRef.nativeElement;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  get empty(): boolean {
    return !this.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return true;
  }

  constructor() {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      if (this.inputElementQueryList && this.containerElement) {
        const inputElement$ = this.inputElementQueryList.changes.pipe(
          startWith(this.inputElementQueryList.first),
          map(() => this.inputElementQueryList?.first),
        );
        this.inputElementQueryListSubscription = inputElement$.subscribe((inputElement) => {
          if (!inputElement) {
            return;
          }

          this.phoneInput?.destroy();

          this.inputElement = inputElement;
          this.phoneInput = intlTelInput(this.inputElement.nativeElement, {
            initialCountry: 'CA',
            nationalMode: true,
            countryOrder: ['CA', 'US'],
            dropdownContainer: this.containerElement,
            strictMode: true,
          });
        });

        this.valueSubscription = this.value$.subscribe((value) => this.phoneInput?.setNumber(value));
      }
    });
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.phoneInput?.destroy();
    this.stateChanges.complete();
    this.valueSubscription?.unsubscribe();
    this.inputElementQueryListSubscription?.unsubscribe();
  }

  writeValue(value: string): void {
    if (this.value !== value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  handleChange(_event: Event): void {
    this.onChange?.(this.value);
    this.onTouched?.();
  }

  handleBlur(): void {
    this.onTouched?.();
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element) !== this.inputElement?.nativeElement) {
      this.inputElement?.nativeElement.focus();
    }
  }

  onFocusIn(_event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.elementRef?.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched?.();
      this.stateChanges.next();
    }
  }

  public updateErrorState(): void {
    const parentSubmitted = this._parentFormGroup?.submitted || this._parentForm?.submitted;
    const touchedOrParentSubmitted = this.touched || parentSubmitted;
    const isPhoneNumberInvalid = this.phoneInput ? !this.phoneInput.isValidNumber() : false;

    const newState = ((this.ngControl?.invalid || isPhoneNumberInvalid) && touchedOrParentSubmitted) ?? false;

    if (this.errorState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
}
