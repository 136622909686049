import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from '@insig-health/config/environment';
import WebFont from 'webfontloader';

import { throwAsyncError } from './main.utilities';

import { init as sentryInit } from '@sentry/angular-ivy';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import { initializeCoreFirebaseApp } from '@insig-health/config/firebase-config';

if (firebase.apps.length === 0) {
  initializeCoreFirebaseApp();
}

let appEnvironment = 'development';
if (environment.production) {
  appEnvironment = 'production';
}

sentryInit({
  dsn: 'https://33c296dda9bc42a59272150b2bfb05cd@o495184.ingest.sentry.io/5686238',
  release: `patient-services@${environment.version}-${appEnvironment}`,
  environment: appEnvironment,
  denyUrls: [/.*localhost.*/],
  attachStacktrace: true,
  beforeSend: (event) => {
    return event;
  },
  tracesSampleRate: 0.0005,
  ignoreErrors: [
    'FirebaseError: [code=permission-denied]',
    'Missing or insufficient permissions.',
    'Failed to execute \'postMessage\' on \'BroadcastChannel\'',
    'This BroadcastChannel is closed',
    'The database connection is closing.',
    '[object Object]',
    '0 Unknown Error',
    'NotReadableError: The I/O read operation failed.',
    'Client is offline.',
    'InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable',
    'places_impl',
    'ChunkLoadError',
    'Could not reach Cloud Firestore backend.',
    'Error: A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
    'Non-Error exception captured with keys: error, headers, message, name, ok',
    'Requested device not found',
    'SpeechRecognitionErrorEvent',
    'Connection to Indexed Database server lost. Refresh the page to try again', // https://bugs.webkit.org/show_bug.cgi?id=197050
    'NotReadableError: Could not start video source',
    'Cannot satisfy constraints',
    'UnknownError: Error looking up record in object store by key range',
    'AbortError: The operation was aborted.',
    `TypeError: 'camera' (value of 'name' member of PermissionDescriptor) is not a valid value for enumeration PermissionName.`,
    'NotAllowedError: Permission denied',
    'NotAllowedError: Permission dismissed',
    'NotAllowedError: The request is not allowed by the user agent',
    'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    'forwardRef render functions accept exactly two parameters',
  ],
});

// Capture all logged errors
window.logError = window.console.error; // Substitute name so that we can call the original function
window.console.error = (...values) => {
  window.logError(...values);
  throwAsyncError(values);
};

if (environment.production) {
  enableProdMode();
  if (
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== 'dev.insig.ca' &&
    window.location.hostname !== 'dev.insighealth.com'
  ) {
    window.console.log = (): void => {
      /* do nothing */
    };
  }
}

WebFont.load({
  google: {
    families: ['Roboto:100,300,400,400italic,500,700:latin'],
  },
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((error) => console.error(error));
