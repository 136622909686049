// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { Pharmacy } from 'insig-types/pharmacy';

import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';
import { LocationService, Region } from '@insig-health/services/location/location.service';
import { PhoneFormatService } from '@insig-health/services/phone-format/phone-format.service';

@Injectable({
  providedIn: 'root',
})
export class PreferredPharmacyService {
  private readonly http = inject(HttpClient);
  private readonly locationService = inject(LocationService);
  private readonly cookieService = inject(CookieService);
  private readonly firestoreService = inject(FirestoreService);
  private readonly phoneFormatService = inject(PhoneFormatService);
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  public MYHEALTH_FAX_NUMBER = '8002883363';

  private sendWellCaRxURL = AWSCLOUDFUNCTIONSENDPOINT + 'virtual/sendWellCaRx';
  private sendPillwayRxURL = AWSCLOUDFUNCTIONSENDPOINT + 'virtual/sendPillwayRx';

  public rexallPharmacyObj = {
    id: 'rexall',
    pharmaName: 'Rexall Pharmacy',
    pharmaFax: '8005638934',
    img:
      'https://firebasestorage.googleapis.com/v0/b/insig-ca.appspot.com/o/pharmacyLogos%2FRexall%20Drugstore-C_2.jpg?alt=media&token=4526c295-534f-405a-bac2-84e165106174',
    line1: 'Rexall Pharmacy provides free prescription delivery across Canada!',
    line2: 'Enjoy free delivery to your doorstep.',
  };

  // Ontario and rest of Canada
  // gets forwarded to their Ontario number
  public static pillwayPharmacyObj = {
    id: 'pillway',
    pharmaName: 'Pillway',
    pharmaFax: '8337455929',
    img: './assets/images/pillway/logo.png',
    img2: './assets/images/pillway/rx.png',
    line1: 'Fill and refill prescriptions online, with free same-day or next business day delivery.',
    line2: 'Prescriptions delivered to your door',
    line3:
      'By choosing Pillway as your preferred pharmacy, you authorize the sending of your prescription, and contact info to Pillway. You also authorize Pillway to send you email and/or SMS.',
  };

  // BC and Alberta
  public pillwayWestPharmacyObj = {
    id: 'pillway',
    pharmaName: 'Pillway',
    pharmaFax: '6046304949',
    img: './assets/images/pillway/logo.png',
    img2: './assets/images/pillway/rx.png',
    line1: 'Fill and refill prescriptions online, with free same-day or next business day delivery.',
    line2: 'Prescriptions delivered to your door',
    line3:
      'By choosing Pillway as your preferred pharmacy, you authorize the sending of your prescription, and contact info to Pillway. You also authorize Pillway to send you email and/or SMS.',
  }

  public alliancePharmacyObj = {
    id: 'alliance',
    pharmaName: 'Alliance Pharmacy',
    pharmaFax: '8778358329',
    img: 'assets/images/alliancepharmacy.png',
    line1:
      'Alliance Pharmacy provides free prescription delivery across Canada, with big savings!',
    line2:
      'Patients often save 20% with Alliance, due to lower dispensing fees and mark-ups.',
  };

  public rexallPharmacyProvinces = {
    NB: { name: 'Rexall Pharmacy', fax: '5066744432' },
    NL: { name: 'Rexall Pharmacy', fax: '5066744432' },
    NS: { name: 'Rexall Pharmacy', fax: '5066744432' },
    PE: { name: 'Rexall Pharmacy', fax: '5066744432' },

    ON: { name: 'Rexall Pharmacy', fax: '8005638934' },

    MB: { name: 'Rexall Pharmacy', fax: '2042614566' },
    SK: { name: 'Rexall Pharmacy', fax: '3067738910' },

    AB: { name: 'Rexall Pharmacy', fax: '7804626349' },

    BC: { name: 'Rexall Pharmacy', fax: '6045965027' },
    NT: { name: 'Rexall Pharmacy', fax: '6045965027' },
    YT: { name: 'Rexall Pharmacy', fax: '6045965027' },
    NU: { name: 'Rexall Pharmacy', fax: '6045965027' },
  }; // end  variable

  public alliancePharmacyProvinces = {
    NB: { name: 'Alliance Pharmacy', fax: '8778358329' },
    NL: { name: 'Alliance Pharmacy', fax: '8778358329' },
    NS: { name: 'Alliance Pharmacy', fax: '8778358329' },
    ON: { name: 'Alliance Pharmacy', fax: '8778358329' },
    PE: { name: 'Alliance Pharmacy', fax: '8778358329' },

    MB: { name: 'Alliance Pharmacy', fax: '8338155756' },
    NU: { name: 'Alliance Pharmacy', fax: '8338155756' },
    SK: { name: 'Alliance Pharmacy', fax: '8338155756' },

    AB: { name: 'Alliance Pharmacy', fax: '8552957174' },
    BC: { name: 'Alliance Pharmacy', fax: '8552957174' },
    NT: { name: 'Alliance Pharmacy', fax: '8552957174' },
    YT: { name: 'Alliance Pharmacy', fax: '8552957174' },
  }; // end  variable

  public wellCaPharmacyObj = {
    id: 'wellCa',
    address: '1168A Dundas St W, Toronto, ON',
    pharmaName: 'Well.ca',
    pharmaFax: '4165340412',
    img: 'assets/images/well-ca.png',
    line1:
      'Well.ca Pharmacy allows you to fill and refill prescriptions online,',
    line2: 'with FREE same day & next day delivery in Toronto!',
    line3:
      'By choosing Well.ca Pharmacy as your preferred pharmacy, you authorize Tia Health to send your prescription, phone number and email address to Well.ca Pharmacy for the purpose of filling your prescription. You also authorize Well.ca Pharmacy to send you an email confirmation at the email address you provided.',
  };

  public wellCaPostalCodes = [
    'M1B',
    'M1C',
    'M1E',
    'M1G',
    'M1H',
    'M1J',
    'M1K',
    'M1L',
    'M1M',
    'M1N',
    'M1P',
    'M1R',
    'M1S',
    'M1T',
    'M1V',
    'M1W',
    'M1X',
    'M2H',
    'M2J',
    'M2K',
    'M2L',
    'M2M',
    'M2N',
    'M2P',
    'M2R',
    'M3A',
    'M3B',
    'M3C',
    'M3H',
    'M3J',
    'M3K',
    'M3L',
    'M3M',
    'M3N',
    'M4A',
    'M4B',
    'M4C',
    'M4E',
    'M4G',
    'M4H',
    'M4J',
    'M4K',
    'M4L',
    'M4M',
    'M4N',
    'M4P',
    'M4R',
    'M4S',
    'M4T',
    'M4V',
    'M4W',
    'M4X',
    'M4Y',
    'M5A',
    'M5B',
    'M5C',
    'M5E',
    'M5G',
    'M5H',
    'M5J',
    'M5K',
    'M5L',
    'M5M',
    'M5N',
    'M5P',
    'M5R',
    'M5S',
    'M5T',
    'M5V',
    'M5W',
    'M5X',
    'M6A',
    'M6B',
    'M6C',
    'M6E',
    'M6G',
    'M6H',
    'M6J',
    'M6K',
    'M6L',
    'M6M',
    'M6N',
    'M6P',
    'M6R',
    'M6S',
    'M7A',
    'M7Y',
    'M8V',
    'M8W',
    'M8X',
    'M8Y',
    'M8Z',
    'M9A',
    'M9B',
    'M9C',
    'M9L',
    'M9M',
    'M9N',
    'M9P',
    'M9R',
    'M9V',
  ];
  // determine whether to hide the pharmacy based on pharmacy cookies already set
  // return a boolean of whether to hide the pharmacy
  shouldHideDefaultPharmacy(): boolean {
    // don't load default pharmacy if cookie set from referral
    const pharmacyFax = this.cookieService.check('pharmacyFax');
    const pharmacyName = this.cookieService.check('pharmacyName');
    return pharmacyFax || pharmacyName;
  }

  // sometimes you don't block pharmacy map totally, but just hide the pharmacy reminder
  shouldHidePharmacyReminder(): boolean {
    const geolocatePharmacy = this.cookieService.check('geolocatePharmacy');
    // checking if on the JNH domain, they're with Walmart so don't show default pharmacy reminder
    const onTheJackNathanHealthDomain = window.location.origin.includes('app.jacknathanhealth.com');
    return geolocatePharmacy || onTheJackNathanHealthDomain;
  }

  checkWellCaPostalCode(postalCode) {
    if (!postalCode) {
      return false;
    }
    postalCode = postalCode.toUpperCase();
    return this.wellCaPostalCodes.includes(postalCode.substring(0, 3));
  }

  // this function calls the getDefaultPharmacyObj and loads the location in
  // so you dont have to load it seperately and pass it in
  // it returns a Pharmacy
  async getDefaultPharmacyObjWithLocation(): Promise<Pharmacy> {
    try {
      const region = await this.locationService.getRegion();
      return this.getDefaultPharmacyObj(region);
    } catch(err) {
      console.error(err);
      // likely an error loading locationObj,
      // continue anyway with empty locationObj
      return this.getDefaultPharmacyObj(null);
    }
  } // end func

  getDefaultPharmacyObj(locationObj: Region | null): Pharmacy {
    // return empty default pharmacy if not in Canada
    if (!locationObj || locationObj.countryAbbreviation !== 'CA') {
      return {};
    }
    // Pillway only delivers for AB, BC and ON now
    if (!!locationObj.regionAbbreviation &&
      (
        locationObj.regionAbbreviation === 'ON' ||
        locationObj.regionAbbreviation === 'BC' ||
        locationObj.regionAbbreviation === 'AB'
      )
    ) {
      // only BC and AB have special number, rest to default
      if (locationObj.regionAbbreviation === 'BC' || locationObj.regionAbbreviation === 'AB') {
        return this.pillwayWestPharmacyObj;
      };
      return PreferredPharmacyService.pillwayPharmacyObj;
    } else {
      return {};
    }
  } // end func

  loadSavedPharmacies(uid): Observable<any> {
    return this.firestoreService.getDocumentAsObservable(
      firebase.firestore().collection('savedPharmacies').doc(uid)
    );
  }

  saveSavedPharmacies(uid, savedPharmacies) {
    const savedPharmaciesObject = {
      lastUpdated: new Date().getTime(),
      savedPharmacies,
    };
    return firebase
      .firestore()
      .collection('savedPharmacies')
      .doc(uid)
      .set(savedPharmaciesObject);
  }

  sendPillwayRx(IDToken, type, rx, patientInfo, doctorInfo, drugs, diagnosis, weight, allergies): any {
    return this.http
      .post(
        this.sendPillwayRxURL,
        {
          IDToken,
          type,
          rx,
          patientInfo,
          doctorInfo,
          drugs,
          diagnosis,
          weight,
          allergies
        },
        { headers: this.headers, responseType: 'text' }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end func

  sendWellCaRx(IDToken, type, rx, patientInfo, doctorInfo, referrer): any {
    return this.http
      .post(
        this.sendWellCaRxURL,
        {
          IDToken,
          type,
          rx,
          patientInfo,
          doctorInfo,
          referrer,
        },
        { headers: this.headers, responseType: 'text' }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end func

  public getPharmacyPartnerByFax(faxNumber: string): string | undefined {
    return this.allPreferredFaxNumbersDictionary[this.phoneFormatService.getSignificantDigitsFormattedPhoneNumber(faxNumber)];
  }

  public allPreferredFaxNumbersDictionary = {
    8778358329: 'Alliance', // alliance
    8338155756: 'Alliance', // alliance
    8552957174: 'Alliance', // alliance
    4165340412: 'Well.ca', // Well.ca
    2897554200: 'M-Line Pharmacy',
    4165555555: 'Dave Test', // Dave test

    5066744432: 'Rexall Direct', // rexall direct
    2042614566: 'Rexall Direct', // rexall direct
    6042152725: 'Rexall Direct', // rexall direct
    7804626349: 'Rexall Direct', // rexall direct
    3067738910: 'Rexall Direct', // rexall direct
    8005638934: 'Rexall Direct', // rexall direct

    9058646040: 'Walmart',
    9056237916: 'Walmart',
    9025438732: 'Walmart',
    4167630355: 'Walmart',
    5197433153: 'Walmart',
    7805143227: 'Walmart',
    7807062259: 'Walmart',
    2503643148: 'Walmart',
    9059891944: 'Walmart',
    5064735262: 'Walmart',
    9028671567: 'Walmart',
    6048154657: 'Walmart',
    9027491698: 'Walmart',
    9022456028: 'Walmart',
    2507200926: 'Walmart',
    2043254775: 'Walmart',
    7057462592: 'Walmart',
    7092793089: 'Walmart',
    2047859213: 'Walmart',
    4038207748: 'Walmart',
    9056408537: 'Walmart',
    7808499489: 'Walmart',
    6135629105: 'Walmart',
    5067358612: 'Walmart',
    4037424495: 'Walmart',
    7099443538: 'Walmart',
    2503151356: 'Walmart',
    5192456803: 'Walmart',
    7054453279: 'Walmart',
    6133542774: 'Walmart',
    9056922640: 'Walmart',
    5063248082: 'Walmart',
    4032238596: 'Walmart',
    6132589489: 'Walmart',
    7807235483: 'Walmart',
    4039345744: 'Walmart',
    4039450245: 'Walmart',
    5064326597: 'Walmart',
    7056468771: 'Walmart',
    9054042852: 'Walmart',
    5193896163: 'Walmart',
    6134460453: 'Walmart',
    9058179706: 'Walmart',
    7808423281: 'Walmart',
    5196273646: 'Walmart',
    3064631338: 'Walmart',
    5064440192: 'Walmart',
    7806248398: 'Walmart',
    9059855477: 'Walmart',
    4036271724: 'Walmart',
    7806326072: 'Walmart',
    5197363185: 'Walmart',
    6132531545: 'Walmart',
    2502877335: 'Walmart',
    4033806986: 'Walmart',
    9054514504: 'Walmart',
    4162984412: 'Walmart',
    9058517460: 'Walmart',
    7054350644: 'Walmart',
    4035568457: 'Walmart',
    7054220956: 'Walmart',
    4033190472: 'Walmart',
    2507683204: 'Walmart',
    7804381531: 'Walmart',
    9058327748: 'Walmart',
    5872303620: 'Walmart',
    2508034405: 'Walmart',
    9057755649: 'Walmart',
    4038877214: 'Walmart',
    6044356437: 'Walmart',
    7055235804: 'Walmart',
    2503051145: 'Walmart',
    9056904151: 'Walmart',
    9054728386: 'Walmart',
    6132244581: 'Walmart',
    5197453857: 'Walmart',
    6044721216: 'Walmart',
    6045042093: 'Walmart',
    9054173874: 'Walmart',
    9057470731: 'Walmart',
    4162652031: 'Walmart',
    6138312914: 'Walmart',
    6048208046: 'Walmart',
    9057931445: 'Walmart',
    9055485113: 'Walmart',
    7804575627: 'Walmart',
    7804642859: 'Walmart',
    5192722850: 'Walmart',
    9056151513: 'Walmart',
    5198434860: 'Walmart',
    4038513045: 'Walmart',
    4033870855: 'Walmart',
    9056128547: 'Walmart',
    7806433015: 'Walmart',
    4166335860: 'Walmart',
    2043261538: 'Walmart',
    2506249285: 'Walmart',
    4032256641: 'Walmart',
    7804083331: 'Walmart',
    7804729126: 'Walmart',
    7804767630: 'Walmart',
    7804522881: 'Walmart',
    7804832659: 'Walmart',
    4164652264: 'Walmart',
    4162450892: 'Walmart',
    9055794301: 'Walmart',
    9053830991: 'Walmart',
    5198932603: 'Walmart',
    5198854221: 'Walmart',
    5199630179: 'Walmart',
    6137477154: 'Walmart',
    6136362169: 'Walmart',
    5192915094: 'Walmart',
    9058303615: 'Walmart',
    7057429277: 'Walmart',
    9056461041: 'Walmart',
    8075776554: 'Walmart',
    8077672199: 'Walmart',
    5066351879: 'Walmart',
    9024625030: 'Walmart',
    9027653567: 'Walmart',
    9027943149: 'Walmart',
    7093680065: 'Walmart',
    6049480642: 'Walmart',
    2049929761: 'Walmart',
    7806703455: 'Walmart',
    9054528916: 'Walmart',
    6043958304: 'Walmart',
    5198212025: 'Walmart',
    6137376893: 'Walmart',
    2042575954: 'Walmart',
    6044958682: 'Walmart',
    6044670916: 'Walmart',
    6045955553: 'Walmart',
    7782842542: 'Walmart',
    9058286818: 'Walmart',
    7787834275: 'Walmart',
    4163350937: 'Walmart',
    9054268209: 'Walmart',
    5065460504: 'Walmart',
    2047263303: 'Walmart',
    5197570035: 'Walmart',
    6133456793: 'Walmart',
    6044215941: 'Walmart',
    4032467636: 'Walmart',
    4032558073: 'Walmart',
    4032476129: 'Walmart',
    4032484063: 'Walmart',
    4032749519: 'Walmart',
    7095965410: 'Walmart',
    5193581366: 'Walmart',
    7094663930: 'Walmart',
    6048541477: 'Walmart',
    6139322660: 'Walmart',
    9024611964: 'Walmart',
    2046380548: 'Walmart',
    8072237958: 'Walmart',
    2507482549: 'Walmart',
    7804625778: 'Walmart',
    7804842618: 'Walmart',
    7804384201: 'Walmart',
    3066341907: 'Walmart',
    4162393992: 'Walmart',
    5064601082: 'Walmart',
    7092567640: 'Walmart',
    9057029015: 'Walmart',
    7094895981: 'Walmart',
    9053872446: 'Walmart',
    7808657186: 'Walmart',
    2503770125: 'Walmart',
    7053354556: 'Walmart',
    2508608172: 'Walmart',
    6133846158: 'Walmart',
    5198930980: 'Walmart',
    4033171691: 'Walmart',
    5194518731: 'Walmart',
    5194729814: 'Walmart',
    5196813690: 'Walmart',
    9059441847: 'Walmart',
    9055420587: 'Walmart',
    9052706081: 'Walmart',
    5068545575: 'Walmart',
    6049843345: 'Walmart',
    3064450366: 'Walmart',
    2503902611: 'Walmart',
    2503523090: 'Walmart',
    9029280131: 'Walmart',
    9058535773: 'Walmart',
    7054762419: 'Walmart',
    9052570287: 'Walmart',
    6138370689: 'Walmart',
    6138201898: 'Walmart',
    5193711659: 'Walmart',
    9026251273: 'Walmart',
    2048578925: 'Walmart',
    2504937318: 'Walmart',
    7057424734: 'Walmart',
    6044859807: 'Walmart',
    3067639056: 'Walmart',
    4033416387: 'Walmart',
    3065432289: 'Walmart',
    3065848911: 'Walmart',
    6134339014: 'Walmart',
    9028655423: 'Walmart',
    5195420353: 'Walmart',
    3063829157: 'Walmart',
    3063731076: 'Walmart',
    7804588913: 'Walmart',
    9056412213: 'Walmart',
    5066343008: 'Walmart',
    7097225170: 'Walmart',
    7093647480: 'Walmart',
    7096438520: 'Walmart',
    9055601779: 'Walmart',
    7055211371: 'Walmart',
    6045883759: 'Walmart',
    3067731705: 'Walmart',
    9025627554: 'Walmart',
    9025627650: 'Walmart',
    2047784677: 'Walmart',
    5196880649: 'Walmart',
    7052682181: 'Walmart',
    4162447466: 'Walmart',
    4165342550: 'Walmart',
    2046541443: 'Walmart',
    2504757506: 'Walmart',
    9057149644: 'Walmart',
    4162844099: 'Walmart',
    7803520533: 'Walmart',
    9056558909: 'Walmart',
    5199690016: 'Walmart',
    5199743158: 'Walmart',
    2044530911: 'Walmart',
    2048970571: 'Walmart',
    2043390410: 'Walmart',
    2042542048: 'Walmart',
    5195395382: 'Walmart',
    8676697079: 'Walmart',
    6139660975: 'Walmart',
    7057289585: 'Walmart',
    8073464063: 'Walmart',
    5195247732: 'Walmart',
    9053045605: 'Walmart',
    7056477978: 'Walmart',
    5193640829: 'Walmart',
    9054578281: 'Walmart',
    6137380153: 'Walmart',
    9053734991: 'Walmart',
    6135995231: 'Walmart',
    9057931592: 'Walmart',
    9024501509: 'Walmart',
    9056379307: 'Walmart',
    5199409208: 'Walmart',
    5197675588: 'Walmart',
    9057611660: 'Walmart',
    7805389359: 'Walmart',
    4035044192: 'Walmart',
    4032015428: 'Walmart',
    5196247458: 'Walmart',
    7053257494: 'Walmart',
    7804640623: 'Walmart',
    7052538454: 'Walmart',
    5197466628: 'Walmart',
    7807906021: 'Walmart',
    6045395290: 'Walmart',
    4166152737: 'Walmart',
    9053710519: 'Walmart',
    9054046586: 'Walmart',
    9026284606: 'Walmart',
    2508988954: 'Walmart',
    5193240966: 'Walmart',
    7057289984: 'Walmart',
    6047927643: 'Walmart',
    7808759740: 'Walmart',
    2505580591: 'Walmart',
    9053199662: 'Walmart',
    6137354666: 'Walmart',
    9056871796: 'Walmart',
    3066933407: 'Walmart',
    3067829826: 'Walmart',
    2044881646: 'Walmart',
    6133946422: 'Walmart',
    3067803711: 'Walmart',
    7806081273: 'Walmart',
    2504895008: 'Walmart',
    9028935590: 'Walmart',
    7096344011: 'Walmart',
    9054262279: 'Walmart',
    2503910813: 'Walmart',
    8676677650: 'Walmart',
    4033585872: 'Walmart',
    9058835304: 'Walmart',
    7097384374: 'Walmart',
    5196377097: 'Walmart',
    2507472368: 'Walmart',
    4162901929: 'Walmart',
    9024548201: 'Walmart',
    7809629076: 'Walmart',
    6138238725: 'Walmart',
    7808402349: 'Walmart',
    9024323578: 'Walmart',
    7055260386: 'Walmart',
    9059911170: 'Walmart',
    4037260441: 'Walmart',
    2509063263: 'Walmart',
    7787835389: 'Walmart',
    9058620737: 'Walmart',
    9058208829: 'Walmart',
    7809861279: 'Walmart',
    4035012722: 'Walmart',
    5068537651: 'Walmart',
    2046877683: 'Walmart',
    2502615556: 'Walmart',
    5067537241: 'Walmart',
    9026816015: 'Walmart',
    4167440672: 'Walmart',
    4039951387: 'Walmart',
    4035671535: 'Walmart',
    9058575401: 'Walmart',
    7057889426: 'Walmart',
    5194265798: 'Walmart',
    7809982903: 'Walmart',
    2507190238: 'Walmart',
    6045241329: 'Walmart',
    9057269020: 'Walmart',
    9026613615: 'Walmart',
    3068426039: 'Walmart',
    8072747349: 'Walmart',
    6132841843: 'Walmart',
    9057097106: 'Walmart',
    5067789471: 'Walmart',
    2506153247: 'Walmart',
    6045976178: 'Walmart',
    6045416074: 'Walmart',
    8074689029: 'Walmart',
    3066538208: 'Walmart',

    7054766975: 'Rexall',
    4163659270: 'Rexall',
    2505981301: 'Rexall',
    9058226817: 'Rexall',
    6138246218: 'Rexall',
    6135318007: 'Rexall',
    4162820941: 'Rexall',
    9057645760: 'Rexall',
    4162395838: 'Rexall',
    4169239089: 'Rexall',
    9056276962: 'Rexall',
    9053185037: 'Rexall',
    9053877870: 'Rexall',
    9053832290: 'Rexall',
    4164948856: 'Rexall',
    4169230150: 'Rexall',
    4169213635: 'Rexall',
    7056452540: 'Rexall',
    4164231401: 'Rexall',
    5198933755: 'Rexall',
    6138258728: 'Rexall',
    6137896930: 'Rexall',
    6132241201: 'Rexall',
    4162985702: 'Rexall',
    6137339842: 'Rexall',
    9055631778: 'Rexall',
    6132837065: 'Rexall',
    6134750965: 'Rexall',
    5197436556: 'Rexall',
    6136878623: 'Rexall',
    6137321931: 'Rexall',
    6134326511: 'Rexall',
    4162643879: 'Rexall',
    4167330563: 'Rexall',
    4169770596: 'Rexall',
    4162260967: 'Rexall',
    4169797089: 'Rexall',
    4163683841: 'Rexall',
    4162484209: 'Rexall',
    4164393892: 'Rexall',
    4165448389: 'Rexall',
    4162225685: 'Rexall',
    4166336857: 'Rexall',
    4162613562: 'Rexall',
    4162914580: 'Rexall',
    4163627013: 'Rexall',
    9054535985: 'Rexall',
    9054543493: 'Rexall',
    6133920917: 'Rexall',
    5198220630: 'Rexall',
    5198362903: 'Rexall',
    9053352522: 'Rexall',
    5194747961: 'Rexall',
    5198220714: 'Rexall',
    9055228403: 'Rexall',
    6132740903: 'Rexall',
    6137457470: 'Rexall',
    6132566270: 'Rexall',
    6132379273: 'Rexall',
    9058583472: 'Rexall',
    5198332904: 'Rexall',
    9058265793: 'Rexall',
    6138285912: 'Rexall',
    7057390035: 'Rexall',
    7057280429: 'Rexall',
    7057250546: 'Rexall',
    7057270887: 'Rexall',
    9055291822: 'Rexall',
    9054579863: 'Rexall',
    9054207261: 'Rexall',
    6135998817: 'Rexall',
    5197438353: 'Rexall',
    6137336395: 'Rexall',
    6137225766: 'Rexall',
    6137244714: 'Rexall',
    9057285374: 'Rexall',
    6135423893: 'Rexall',
    7055660900: 'Rexall',
    6137286526: 'Rexall',
    9056620026: 'Rexall',
    5195248213: 'Rexall',
    9058316330: 'Rexall',
    9057325862: 'Rexall',
    7052533534: 'Rexall',
    5197971599: 'Rexall',
    9059949100: 'Rexall',
    5193643592: 'Rexall',
    6133422280: 'Rexall',
    5198326458: 'Rexall',
    9056838740: 'Rexall',
    9054287308: 'Rexall',
    5193969154: 'Rexall',
    5194320410: 'Rexall',
    9053891578: 'Rexall',
    5194427662: 'Rexall',
    5196814188: 'Rexall',
    5197445102: 'Rexall',
    5196578976: 'Rexall',
    5194579011: 'Rexall',
    9053540640: 'Rexall',
    5194434741: 'Rexall',
    5198531855: 'Rexall',
    5196458280: 'Rexall',
    5194714435: 'Rexall',
    5196270627: 'Rexall',
    5197361764: 'Rexall',
    6138296091: 'Rexall',
    6137323656: 'Rexall',
    4169248096: 'Rexall',
    6137293271: 'Rexall',
    6137379315: 'Rexall',
    4164848735: 'Rexall',
    4162397549: 'Rexall',
    9052770812: 'Rexall',
    9058139741: 'Rexall',
    9056372546: 'Rexall',
    9057857746: 'Rexall',
    7058783332: 'Rexall',
    7054366004: 'Rexall',
    9057650551: 'Rexall',
    6135929327: 'Rexall',
    6137414982: 'Rexall',
    6132830194: 'Rexall',
    7053248195: 'Rexall',
    9056834457: 'Rexall',
    6139668573: 'Rexall',
    6134767373: 'Rexall',
    4165372532: 'Rexall',
    7053257203: 'Rexall',
    9057753976: 'Rexall',
    5193715343: 'Rexall',
    5193765155: 'Rexall',
    5199415684: 'Rexall',
    9054175430: 'Rexall',
    7054354861: 'Rexall',
    9052700826: 'Rexall',
    9054598051: 'Rexall',
    4166616057: 'Rexall',
    9053198672: 'Rexall',
    9058498595: 'Rexall',
    9058258649: 'Rexall',
    9052275349: 'Rexall',
    5197463090: 'Rexall',
    5197451801: 'Rexall',
    5197439450: 'Rexall',
    4167873971: 'Rexall',
    9058406392: 'Rexall',
    2044778456: 'Rexall',
    2042554777: 'Rexall',
    2046611313: 'Rexall',
    2048326650: 'Rexall',
    2042554889: 'Rexall',
    2047728010: 'Rexall',
    2043386833: 'Rexall',
    2049470182: 'Rexall',
    2047859807: 'Rexall',
    5194214002: 'Rexall',
    7052643775: 'Rexall',
    8078761053: 'Rexall',
    4164386192: 'Rexall',
    7057428622: 'Rexall',
    7057483989: 'Rexall',
    5194213406: 'Rexall',
    7053371877: 'Rexall',
    5193323749: 'Rexall',
    7055230751: 'Rexall',
    4162593271: 'Rexall',
    5193633554: 'Rexall',
    4162865325: 'Rexall',
    9057329640: 'Rexall',
    9058577337: 'Rexall',
    7058480531: 'Rexall',
    7055607032: 'Rexall',
    8078540141: 'Rexall',
    9058523649: 'Rexall',
    6132676084: 'Rexall',
    9052097968: 'Rexall',
    6135488859: 'Rexall',
    8078871131: 'Rexall',
    9057908289: 'Rexall',
    7055255549: 'Rexall',
    4169771102: 'Rexall',
    4162511297: 'Rexall',
    4164614026: 'Rexall',
    6133540891: 'Rexall',
    9055476719: 'Rexall',
    6136235353: 'Rexall',
    4167801069: 'Rexall',
    7058976948: 'Rexall',
    7057342142: 'Rexall',
    7059835638: 'Rexall',
    8077371361: 'Rexall',
    7057251941: 'Rexall',
    9058762368: 'Rexall',
    9059539370: 'Rexall',
    7059492775: 'Rexall',
    6138207928: 'Rexall',
    6136923366: 'Rexall',
    7057590693: 'Rexall',
    5194510205: 'Rexall',
    4169269352: 'Rexall',
    4165913553: 'Rexall',
    4164835486: 'Rexall',
    7054575132: 'Rexall',
    2503392230: 'Rexall',
    2503851870: 'Rexall',
    2509496598: 'Rexall',
    6049373143: 'Rexall',
    2502462579: 'Rexall',
    2502463511: 'Rexall',
    6049326324: 'Rexall',
    6049329755: 'Rexall',
    2503848794: 'Rexall',
    2503722257: 'Rexall',
    2503884084: 'Rexall',
    2503859672: 'Rexall',
    2504771319: 'Rexall',
    2504785508: 'Rexall',
    6046847329: 'Rexall',
    6045888342: 'Rexall',
    6045767829: 'Rexall',
    6042352118: 'Rexall',
    2506528537: 'Rexall',
    2507538945: 'Rexall',
    6046834248: 'Rexall',
    2503389470: 'Rexall',
    2507539862: 'Rexall',
    2507684038: 'Rexall',
    6047927708: 'Rexall',
    2503846313: 'Rexall',
    7807864999: 'Rexall',
    7807786365: 'Rexall',
    7806223915: 'Rexall',
    7803494344: 'Rexall',
    7805242859: 'Rexall',
    7803334599: 'Rexall',
    7809867478: 'Rexall',
    4035562234: 'Rexall',
    7809982052: 'Rexall',
    7808492427: 'Rexall',
    7804822366: 'Rexall',
    4037836699: 'Rexall',
    4037827610: 'Rexall',
    7809298809: 'Rexall',
    7809620565: 'Rexall',
    4035679105: 'Rexall',
    7804399281: 'Rexall',
    7804616445: 'Rexall',
    7804171492: 'Rexall',
    7804493171: 'Rexall',
    7804764069: 'Rexall',
    7804764341: 'Rexall',
    4036527300: 'Rexall',
    7809639889: 'Rexall',
    7804161136: 'Rexall',
    7805421552: 'Rexall',
    7804885481: 'Rexall',
    7804402474: 'Rexall',
    7804350144: 'Rexall',
    7804380355: 'Rexall',
    7808651144: 'Rexall',
    7804475221: 'Rexall',
    4032351375: 'Rexall',
    7804575087: 'Rexall',
    4032445333: 'Rexall',
    4032755603: 'Rexall',
    7804558264: 'Rexall',
    4036275024: 'Rexall',
    4039323447: 'Rexall',
    4036784385: 'Rexall',
    4033463620: 'Rexall',
    4033094426: 'Rexall',
    4035268945: 'Rexall',
    4035288848: 'Rexall',
    7804757441: 'Rexall',
    4036473641: 'Rexall',
    4032814170: 'Rexall',
    4032033318: 'Rexall',
    4032778382: 'Rexall',
    4032573621: 'Rexall',
    4032490036: 'Rexall',
    4032078846: 'Rexall',
    4032080523: 'Rexall',
    4032937252: 'Rexall',
    7804841496: 'Rexall',
    3066526172: 'Rexall',
    3069751506: 'Rexall',
    3065434599: 'Rexall',
    3066944711: 'Rexall',
    3067524415: 'Rexall',
    3066982814: 'Rexall',
    2043245650: 'Rexall',
    2049894034: 'Rexall',
    2049424486: 'Rexall',
    2042541021: 'Rexall',
    5192503989: 'Rexall',
    5193221148: 'Rexall',
    5192562634: 'Rexall',
    5193581796: 'Rexall',
    5199678321: 'Rexall',
    5199731293: 'Rexall',
    6136571466: 'Rexall',
    6137457547: 'Rexall',
    7054445562: 'Rexall',
    4165339303: 'Rexall',
    4169293912: 'Rexall',
    6137882746: 'Rexall',
    9054769586: 'Rexall',
    7056752985: 'Rexall',
    6138248665: 'Rexall',
    6135998614: 'Rexall',
    5193767153: 'Rexall',
    9056410869: 'Rexall',
    9053858662: 'Rexall',
    5197527786: 'Rexall',
    9055225820: 'Rexall',
    9053832893: 'Rexall',
    9056481222: 'Rexall',
    9057742641: 'Rexall',
    9055476328: 'Rexall',
    9053886817: 'Rexall',
    9057327149: 'Rexall',
    5197591796: 'Rexall',
    7804071090: 'Rexall',
    7807355258: 'Rexall',
    5194361444: 'Rexall',
    4039552499: 'Rexall',
    4032916749: 'Rexall',
    4039442227: 'Rexall',
    9057739901: 'Rexall',
    4164139676: 'Rexall',
    3066483455: 'Rexall',
    6048202146: 'Rexall',
    7803542350: 'Rexall',
    7804561589: 'Rexall',
    6135909510: 'Rexall',
    7804074886: 'Rexall',
    5879559099: 'Rexall',
    7808642160: 'Rexall',
    7804580719: 'Rexall',
    6048016997: 'Rexall',
    2504772285: 'Rexall',
    2503867659: 'Rexall',
    2505908798: 'Rexall',
    4165917469: 'Rexall',
    7803872207: 'Rexall',
    6139622268: 'Rexall',
    6043951448: 'Rexall',
    4166034889: 'Rexall',
    2504937577: 'Rexall',
    7806280236: 'Rexall',
    7803416763: 'Rexall',
    7809872186: 'Rexall',
    4166075556: 'Rexall',
    4167337943: 'Rexall',
    6045364261: 'Rexall',
    6045368405: 'Rexall',
    6045319299: 'Rexall',
    7808425326: 'Rexall',
    8075974695: 'Rexall',
    5198228600: 'Rexall',
    5196209228: 'Rexall',
    6044389375: 'Rexall',
    7806340473: 'Rexall',
    4166076390: 'Rexall',
    4163237523: 'Rexall',
    4162486217: 'Rexall',
    8076238945: 'Rexall',
    6045366864: 'Rexall',
    2043392221: 'Rexall',
    2044679123: 'Rexall',
    6044852924: 'Rexall',
    5874869478: 'Rexall',
    4169798377: 'Rexall',
    4169298903: 'Rexall',
    4166200699: 'Rexall',
    5195381962: 'Rexall',
    6138254873: 'Rexall',
    4165968792: 'Rexall',
    2042683710: 'Rexall',
    7056457639: 'Rexall',
    6132324547: 'Rexall',
    3067520016: 'Rexall',
    7808353737: 'Rexall',
    7803321665: 'Rexall',
    4163663922: 'Rexall',
    4165310097: 'Rexall',
    9058463058: 'Rexall',
    6049133159: 'Rexall',
    4165398790: 'Rexall',
    7804240787: 'Rexall',
    4039438866: 'Rexall',
    9056423003: 'Rexall',
    5193221790: 'Rexall',
    4164856171: 'Rexall',
    9056691330: 'Rexall',
    2042224709: 'Rexall',
    9055086597: 'Rexall',

    7805700806: 'The Medicine Shoppe Pharmacy',
    2503395040: 'The Medicine Shoppe Pharmacy',
    7804741216: 'The Medicine Shoppe Pharmacy',
    7804881180: 'The Medicine Shoppe Pharmacy',
    5194345166: 'The Medicine Shoppe Pharmacy',
    3066554048: 'The Medicine Shoppe Pharmacy',
    3063597763: 'The Medicine Shoppe Pharmacy',
    5063440829: 'The Medicine Shoppe Pharmacy',
    9058420027: 'The Medicine Shoppe Pharmacy',
    9057650606: 'The Medicine Shoppe Pharmacy',
    4032350878: 'The Medicine Shoppe Pharmacy',
    3062447686: 'The Medicine Shoppe Pharmacy',
    3063430113: 'The Medicine Shoppe Pharmacy',
    7804364576: 'The Medicine Shoppe Pharmacy',
    5068548082: 'The Medicine Shoppe Pharmacy',
    6046756320: 'The Medicine Shoppe Pharmacy',
    9056810644: 'The Medicine Shoppe Pharmacy',
    7804775127: 'The Medicine Shoppe Pharmacy',
    7094666601: 'The Medicine Shoppe Pharmacy',
    2502763076: 'The Medicine Shoppe Pharmacy',
    5066228323: 'The Medicine Shoppe Pharmacy',
    9024448086: 'The Medicine Shoppe Pharmacy',
    4033810760: 'The Medicine Shoppe Pharmacy',
    6046380194: 'The Medicine Shoppe Pharmacy',
    9024612272: 'The Medicine Shoppe Pharmacy',
    6042783839: 'The Medicine Shoppe Pharmacy',
    4036525510: 'The Medicine Shoppe Pharmacy',
    2044219210: 'The Medicine Shoppe Pharmacy',
    7804876300: 'The Medicine Shoppe Pharmacy',
    2507638289: 'The Medicine Shoppe Pharmacy',
    9028534668: 'The Medicine Shoppe Pharmacy',
    7807601121: 'The Medicine Shoppe Pharmacy',
    3065225302: 'The Medicine Shoppe Pharmacy',
    7096860764: 'The Medicine Shoppe Pharmacy',
    3067570501: 'The Medicine Shoppe Pharmacy',
    8074685625: 'The Medicine Shoppe Pharmacy',
    6045103141: 'The Medicine Shoppe Pharmacy',
    4032537390: 'The Medicine Shoppe Pharmacy',
    3065464465: 'The Medicine Shoppe Pharmacy',
    7804360198: 'The Medicine Shoppe Pharmacy',
    7804841212: 'The Medicine Shoppe Pharmacy',
    7809989072: 'The Medicine Shoppe Pharmacy',
    3069311113: 'The Medicine Shoppe Pharmacy',
    3069310002: 'The Medicine Shoppe Pharmacy',
    3069310177: 'The Medicine Shoppe Pharmacy',
    4163987948: 'The Medicine Shoppe Pharmacy',
    4032743454: 'The Medicine Shoppe Pharmacy',
    4033803426: 'The Medicine Shoppe Pharmacy',
    7805233433: 'The Medicine Shoppe Pharmacy',
    9058320913: 'The Medicine Shoppe Pharmacy',
    4165387024: 'The Medicine Shoppe Pharmacy',
    2049884833: 'The Medicine Shoppe Pharmacy',
    4166515322: 'The Medicine Shoppe Pharmacy',
    4162219322: 'The Medicine Shoppe Pharmacy',
    9055470718: 'The Medicine Shoppe Pharmacy',
    6137453849: 'The Medicine Shoppe Pharmacy',
    5194712303: 'The Medicine Shoppe Pharmacy',
    6048545803: 'The Medicine Shoppe Pharmacy',
    4032214349: 'The Medicine Shoppe Pharmacy',
    7052537656: 'The Medicine Shoppe Pharmacy',
    6045316560: 'The Medicine Shoppe Pharmacy',
    3062420221: 'The Medicine Shoppe Pharmacy',
    2502488991: 'The Medicine Shoppe Pharmacy',
    7804618673: 'The Medicine Shoppe Pharmacy',
    4032910075: 'The Medicine Shoppe Pharmacy',
    9027525453: 'The Medicine Shoppe Pharmacy',
    5068695229: 'The Medicine Shoppe Pharmacy',
    6047405814: 'The Medicine Shoppe Pharmacy',
    5063951727: 'The Medicine Shoppe Pharmacy',
    9028954091: 'The Medicine Shoppe Pharmacy',
    4032865290: 'The Medicine Shoppe Pharmacy',
    4033740350: 'The Medicine Shoppe Pharmacy',
    4162821802: 'The Medicine Shoppe Pharmacy',
    7807021805: 'The Medicine Shoppe Pharmacy',
    9057211544: 'The Medicine Shoppe Pharmacy',
    5064531338: 'The Medicine Shoppe Pharmacy',
    4032294358: 'The Medicine Shoppe Pharmacy',
    2505951325: 'The Medicine Shoppe Pharmacy',
    4032882388: 'The Medicine Shoppe Pharmacy',
    7804598583: 'The Medicine Shoppe Pharmacy',
    9053771290: 'The Medicine Shoppe Pharmacy',
    5194481728: 'The Medicine Shoppe Pharmacy',
    5194350676: 'The Medicine Shoppe Pharmacy',
    9025395071: 'The Medicine Shoppe Pharmacy',
    6043273803: 'The Medicine Shoppe Pharmacy',
    9028591308: 'The Medicine Shoppe Pharmacy',
    7059429931: 'The Medicine Shoppe Pharmacy',
    9057212068: 'The Medicine Shoppe Pharmacy',
    7807920732: 'The Medicine Shoppe Pharmacy',
    6048762519: 'The Medicine Shoppe Pharmacy',
    4038730095: 'The Medicine Shoppe Pharmacy',
    7804288928: 'The Medicine Shoppe Pharmacy',
    3062424913: 'The Medicine Shoppe Pharmacy',
    5193376999: 'The Medicine Shoppe Pharmacy',
    7804260640: 'The Medicine Shoppe Pharmacy',
    5195710291: 'The Medicine Shoppe Pharmacy',
    5068586160: 'The Medicine Shoppe Pharmacy',
    2507234924: 'The Medicine Shoppe Pharmacy',
    6044684707: 'The Medicine Shoppe Pharmacy',
    2504776121: 'The Medicine Shoppe Pharmacy',
    9053099922: 'The Medicine Shoppe Pharmacy',
    3063524659: 'The Medicine Shoppe Pharmacy',
    9025671433: 'The Medicine Shoppe Pharmacy',
    2507072954: 'The Medicine Shoppe Pharmacy',
    4034559940: 'The Medicine Shoppe Pharmacy',
    5065326603: 'The Medicine Shoppe Pharmacy',
    9024064115: 'The Medicine Shoppe Pharmacy',
    7809868630: 'The Medicine Shoppe Pharmacy',
    4162221844: 'The Medicine Shoppe Pharmacy',
    9022527701: 'The Medicine Shoppe Pharmacy',
    6132321606: 'The Medicine Shoppe Pharmacy',
    5064540075: 'The Medicine Shoppe Pharmacy',
    4164929216: 'The Medicine Shoppe Pharmacy',
    7059428369: 'The Medicine Shoppe Pharmacy',
    9024448702: 'The Medicine Shoppe Pharmacy',
    2046611949: 'The Medicine Shoppe Pharmacy',
    3067751384: 'The Medicine Shoppe Pharmacy',
    9026258870: 'The Medicine Shoppe Pharmacy',
    9058762447: 'The Medicine Shoppe Pharmacy',
    2042757855: 'The Medicine Shoppe Pharmacy',
    6044355358: 'The Medicine Shoppe Pharmacy',
    3066933521: 'The Medicine Shoppe Pharmacy',
    5068541110: 'The Medicine Shoppe Pharmacy',
    7095324059: 'The Medicine Shoppe Pharmacy',
    4162928424: 'The Medicine Shoppe Pharmacy',
    9056625337: 'The Medicine Shoppe Pharmacy',
    3065435666: 'The Medicine Shoppe Pharmacy',
    3066492232: 'The Medicine Shoppe Pharmacy',
    3065462553: 'The Medicine Shoppe Pharmacy',
    2048854513: 'The Medicine Shoppe Pharmacy',
    7782975778: 'The Medicine Shoppe Pharmacy',
    2043398787: 'The Medicine Shoppe Pharmacy',
    4032010184: 'The Medicine Shoppe Pharmacy',
    7804796321: 'The Medicine Shoppe Pharmacy',
    7092661050: 'The Medicine Shoppe Pharmacy',
    4037343445: 'The Medicine Shoppe Pharmacy',
    5063257220: 'The Medicine Shoppe Pharmacy',
    7097472895: 'The Medicine Shoppe Pharmacy',
    2505850327: 'The Medicine Shoppe Pharmacy',
    3069703260: 'The Medicine Shoppe Pharmacy',
    2047177202: 'The Medicine Shoppe Pharmacy',
    9022543078: 'The Medicine Shoppe Pharmacy',
    2045049334: 'The Medicine Shoppe Pharmacy',
    2507600083: 'The Medicine Shoppe Pharmacy',
    2507237271: 'The Medicine Shoppe Pharmacy',
    8679207785: 'The Medicine Shoppe Pharmacy',
    9056200055: 'The Medicine Shoppe Pharmacy',
    3063827102: 'The Medicine Shoppe Pharmacy',
    9028933501: 'The Medicine Shoppe Pharmacy',
    7804559080: 'The Medicine Shoppe Pharmacy',
    3066682257: 'The Medicine Shoppe Pharmacy',
    7804772766: 'The Medicine Shoppe Pharmacy',
    7804729925: 'The Medicine Shoppe Pharmacy',
    7804729323: 'The Medicine Shoppe Pharmacy',
    5067383350: 'The Medicine Shoppe Pharmacy',
    7807432228: 'The Medicine Shoppe Pharmacy',
    2505914935: 'The Medicine Shoppe Pharmacy',
    2503238689: 'The Medicine Shoppe Pharmacy',
    7807058151: 'The Medicine Shoppe Pharmacy',
    7096322447: 'The Medicine Shoppe Pharmacy',
    6047761100: 'The Medicine Shoppe Pharmacy',
    7784773812: 'The Medicine Shoppe Pharmacy',
    7805711501: 'The Medicine Shoppe Pharmacy',
    7802508082: 'The Medicine Shoppe Pharmacy',
    9028432987: 'The Medicine Shoppe Pharmacy',
    3069551056: 'The Medicine Shoppe Pharmacy',
    7094891224: 'The Medicine Shoppe Pharmacy',
    7784343303: 'The Medicine Shoppe Pharmacy',
    7806757123: 'The Medicine Shoppe Pharmacy',
    2507239820: 'The Medicine Shoppe Pharmacy',
    7804301737: 'The Medicine Shoppe Pharmacy',
    4033940401: 'The Medicine Shoppe Pharmacy',
    7807057140: 'The Medicine Shoppe Pharmacy',
    6043271426: 'The Medicine Shoppe Pharmacy',
    7804610303: 'The Medicine Shoppe Pharmacy',
    5874868808: 'The Medicine Shoppe Pharmacy',
    3062360334: 'The Medicine Shoppe Pharmacy',
    4035230901: 'The Medicine Shoppe Pharmacy',
    7805399760: 'The Medicine Shoppe Pharmacy',
    7097471827: 'The Medicine Shoppe Pharmacy',
    6044895920: 'The Medicine Shoppe Pharmacy',
    7804858092: 'The Medicine Shoppe Pharmacy',
    9028202111: 'The Medicine Shoppe Pharmacy',
    7807563387: 'The Medicine Shoppe Pharmacy',
    3067822001: 'The Medicine Shoppe Pharmacy',
    6043801600: 'The Medicine Shoppe Pharmacy',
    5875202475: 'The Medicine Shoppe Pharmacy',
    7803522271: 'The Medicine Shoppe Pharmacy',
    7784751019: 'The Medicine Shoppe Pharmacy',
    7054507435: 'The Medicine Shoppe Pharmacy',
    2046235777: 'The Medicine Shoppe Pharmacy',
    4034528344: 'The Medicine Shoppe Pharmacy',
    7096354019: 'The Medicine Shoppe Pharmacy',
    5064505465: 'The Medicine Shoppe Pharmacy',
    5062067668: 'The Medicine Shoppe Pharmacy',
    7808492956: 'The Medicine Shoppe Pharmacy',
    7809689747: 'The Medicine Shoppe Pharmacy',
    5876892067: 'The Medicine Shoppe Pharmacy',
    6047321199: 'The Medicine Shoppe Pharmacy',
    3065592221: 'The Medicine Shoppe Pharmacy',
    2505914557: 'The Medicine Shoppe Pharmacy',
    6042297975: 'The Medicine Shoppe Pharmacy',
    7057439909: 'The Medicine Shoppe Pharmacy',
    6045540953: 'The Medicine Shoppe Pharmacy',
    6045219614: 'The Medicine Shoppe Pharmacy',
    2507528941: 'The Medicine Shoppe Pharmacy',

    6473503531: 'Mednow Pharmacy',

    4162911957: 'Pillway', // Ontario
    6046304949: 'Pillway', // AB and BC
    8337455929: 'Pillway', // Default / rest of Canada - forwards to Ontario
  };
} // end service
