<label class="w-100"
  [attr.aria-describedby]="userAriaDescribedBy"
  [attr.aria-labelledby]="parentFormField?.getLabelId()"
>
  <span class="bold textSecondary">{{ label }}</span>
  <span *ngIf="required" class="required">&nbsp;*</span>
  <div id="inputDivWrapper">
    <input type="tel"
      matInput
      id="inputElement"
      #inputElement
      name="phone"
      class="bold fs-6"
      [name]="name"
      [readonly]="isReadOnly"
      [disabled]="disabled"
      [value]="value$ | async"
      (change)="handleChange($event)"
      (blur)="handleBlur()"
      maxlength="16"
    />
  </div>
</label>
