// @ts-strict-ignore
import { take } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { PatientRegistration } from '@insig-health/api/patient-registration-api';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';
import { FamilyMember, FamilyMemberProfile, PatientProfile, PatientUserData } from 'insig-types/user-data';
import { PatientTermsOfUseComponent } from '@insig-health/patient-terms-of-use/patient-terms-of-use.component';

export interface Province {
  name: string,
  id: string,
}

export interface PhysioInfo {
  admin: boolean;
  referrer: string;
  customBookingURL: string;
  specialPrice: {
    price: number;
    split: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PatientUserDataService {
  private readonly http = inject(HttpClient);
  private readonly firestoreService = inject(FirestoreService);
  private createPatientUserUrl =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/createPatientUser';
  private createPatientUserProxyURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/createPatientUserProxy';
  private getPatientDataFromAppointmentIDURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/getPatientDataFromAppointmentID';
  private saveCPPToInsigUserURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/saveCPPToInsigUser';
  private saveCPPToNonUserPatientURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/saveCPPToNonUserPatient';
  private checkIfPatientIsInsuredUrl =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/checkIfUserIsInsured';
  private createPatientCommunicationUrl =
    AWSCLOUDFUNCTIONSENDPOINT + 'communications/createPatientCommunication';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public signUpForm: UntypedFormGroup;

  public physioInfo: [Record<string, never>, PhysioInfo, PhysioInfo] = [
    {}, // element of index 0 is left empty
    {
      admin: true,
      referrer: 'delaney',
      customBookingURL: 'https://tiahealth.com/pinnacle.html',
      specialPrice: {
        price: 40,
        split: 0.90,
      },
    },
    {
      admin: true,
      referrer: 'delaney',
      customBookingURL: 'https://tiahealth.com/pinnacle.html',
      specialPrice: {
        price: 65,
        split: 0.63,
      },
    },
  ];

  public provinces: Province[] = [
    {
      name: 'Alberta',
      id: 'AB',
    },
    {
      name: 'British Columbia',
      id: 'BC',
    },
    {
      name: 'Manitoba',
      id: 'MB',
    },
    {
      name: 'New Brunswick',
      id: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      id: 'NL',
    },
    {
      name: 'Northwest Territories',
      id: 'NT',
    },
    {
      name: 'Nova Scotia',
      id: 'NS',
    },
    {
      name: 'Nunavut',
      id: 'NU',
    },
    {
      name: 'Ontario',
      id: 'ON',
    },
    {
      name: 'Prince Edward Island',
      id: 'PE',
    },
    {
      name: 'Quebec',
      id: 'QC',
    },
    {
      name: 'Saskatchewan',
      id: 'SK',
    },
    {
      name: 'Yukon',
      id: 'YT',
    },
  ];

  public americanStates: Province[] = [
    { name: 'Alabama', id: 'AL' },
    { name: 'Alaska', id: 'AK' },
    { name: 'Arizona', id: 'AZ' },
    { name: 'Arkansas', id: 'AR' },
    { name: 'California', id: 'CA' },
    { name: 'Colorado', id: 'CO' },
    { name: 'Connecticut', id: 'CT' },
    { name: 'Delaware', id: 'DE' },
    { name: 'Florida', id: 'FL' },
    { name: 'Georgia', id: 'GA' },
    { name: 'Hawaii', id: 'HI' },
    { name: 'Idaho', id: 'ID' },
    { name: 'Illinois', id: 'IL' },
    { name: 'Indiana', id: 'IN' },
    { name: 'Iowa', id: 'IA' },
    { name: 'Kansas', id: 'KS' },
    { name: 'Kentucky', id: 'KY' },
    { name: 'Louisiana', id: 'LA' },
    { name: 'Maine', id: 'ME' },
    { name: 'Maryland', id: 'MD' },
    { name: 'Massachusetts', id: 'MA' },
    { name: 'Michigan', id: 'MI' },
    { name: 'Minnesota', id: 'MN' },
    { name: 'Mississippi', id: 'MS' },
    { name: 'Missouri', id: 'MO' },
    { name: 'Montana', id: 'MT' },
    { name: 'Nebraska', id: 'NE' },
    { name: 'Nevada', id: 'NV' },
    { name: 'New Hampshire', id: 'NH' },
    { name: 'New Jersey', id: 'NJ' },
    { name: 'New Mexico', id: 'NM' },
    { name: 'New York', id: 'NY' },
    { name: 'North Carolina', id: 'NC' },
    { name: 'North Dakota', id: 'ND' },
    { name: 'Ohio', id: 'OH' },
    { name: 'Oklahoma', id: 'OK' },
    { name: 'Oregon', id: 'OR' },
    { name: 'Pennsylvania', id: 'PA' },
    { name: 'Rhode Island', id: 'RI' },
    { name: 'South Carolina', id: 'SC' },
    { name: 'South Dakota', id: 'SD' },
    { name: 'Tennessee', id: 'TN' },
    { name: 'Texas', id: 'TX' },
    { name: 'Utah', id: 'UT' },
    { name: 'Vermont', id: 'VT' },
    { name: 'Virginia', id: 'VA' },
    { name: 'Washington', id: 'WA' },
    { name: 'West Virginia', id: 'WV' },
    { name: 'Wisconsin', id: 'WI' },
    { name: 'Wyoming', id: 'WY' },
  ];

  public provinceDictionary = {
    Ontario: 'ON',
    Alberta: 'AB',
    'British Columbia': 'BC',
    Manitoba: 'MB',
    'New Brunswick': 'NB',
    'Newfoundland and Labrador': 'NL',
    'Nova Scotia': 'NS',
    'Prince Edward Island': 'PE',
    Quebec: 'QC',
    Saskatchewan: 'SK',
    'Northwest Territories': 'NT',
    Nunavut: 'NU',
    Yukon: 'YT',
  };

  async createPatientCommunication(
    type: string,
    communication: { body: string },
    timestamp: number,
    now: boolean,
    patientID: string,
    checkIfExists: boolean,
    identifier: string,
  ): Promise<void> {
    await this.http
      .post(
        this.createPatientCommunicationUrl,
        JSON.stringify({
          type,
          communication,
          timestamp,
          now,
          patientID,
          checkIfExists,
          identifier,
        }),
        { headers: this.headers, responseType: 'text' },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async createPatientAccount(patientUserData: {
    tiaTermsVer2?: number;
    email?: string;
    password?: string;
  }, password: string): Promise<void> {
    await this.http
      .post(
        this.createPatientUserUrl,
        JSON.stringify({
          patientUserData,
          password,
          email: patientUserData.email,
        }),
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async createPatientUserProxy(
    patientUserData: Partial<PatientUserData>,
    email: string,
    password: string,
  ): Promise<string> {
    return this.http
      .post(
        this.createPatientUserProxyURL,
        {
          patientUserData,
          email,
          password,
        },
        { headers: this.headers, responseType: 'text' },
      )
      .toPromise();
  }

  getOneFamilyMember(familyID: string): Promise<firebase.firestore.DocumentSnapshot> {
    return firebase
      .firestore()
      .collection('familyMembers/')
      .doc(familyID)
      .get();
  }

  async createFamilyMember(familyMemberUserData: FamilyMemberProfile, patientID: string): Promise<firebase.firestore.DocumentReference> {
    return await firebase
      .firestore()
      .collection('familyMembers/')
      .add({
        patientID,
        data: familyMemberUserData,
      });
  }

  async deleteFamilyMember(familyID: string): Promise<void> {
    const toUpdate = {
      deleted: true,
    };
    await firebase
      .firestore()
      .collection('familyMembers/')
      .doc(familyID)
      .update(toUpdate)
      .then(() => {
        console.log('member deleted!');
      })
      .catch((error) => {
        console.error('Error deleting member: ', error);
      });
  }

  async updateFamilyMemberCPP(
    familyID: string,
    familyMemberCPP: any,
  ): Promise<void> {
    return firebase
      .firestore()
      .collection('familyMembers/')
      .doc(familyID)
      .update({
        cpp: familyMemberCPP
      });
  }

  async editFamilyMember(
    familyID: string,
    familyMemberUserData: FamilyMemberProfile,
  ): Promise<void> {
    return firebase
      .firestore()
      .collection('familyMembers/')
      .doc(familyID)
      .update({
        data: familyMemberUserData,
      });
  }

  loadFamilyMembers(patientID: string): Observable<Array<FamilyMember & { id: string }>> {
    return Observable.create((observer) => {
      return firebase.firestore().collection('familyMembers')
        .where('patientID', '==', patientID)
        .onSnapshot({
          next: (querySnapshot) => {
            observer.next(querySnapshot.docs.map((document) => {
              const data = document.data();
              const id = document.id;
              return { ...data, id };
            }));
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
        });
    });
  }

  getPatientDataFromAppointmentID(appointmentID: string, IDToken: string): Promise<{ body: PatientProfile }> {
    return this.http
      .post<{ body: PatientProfile }>(
        this.getPatientDataFromAppointmentIDURL,
        JSON.stringify({
          appointmentID,
          IDToken,
        }),
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  /**
   * @firestore Firestore migration
   */
  saveCPPData(userId: string, cppData: Record<string, any>): Promise<void> {
    // TODO Add strict typing
    cppData = this.firestoreService.removeNestedUndefined(cppData);
    if (cppData.doctors) {
      if (!cppData.doctors?.property?.[0]) {
        cppData.doctors.property = [];
      }
      if (!cppData.doctors?.selectedAnswers?.[0]) {
        cppData.doctors.selectedAnswers = [];
      }
    }

    return firebase
      .firestore()
      .collection('patientUsers')
      .doc(userId)
      .update({ cpp: cppData });
  }

  async saveCPPDataInsigUser(IDToken: string, userID: string, cppData: Record<string, string>): Promise<void> {
    console.log('saving company cpp data');

    await this.http
      .post(
        this.saveCPPToInsigUserURL,
        JSON.stringify({
          IDToken,
          userID,
          cppData,
        }),
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async saveCPPDataToCompany(companyID: string, patientHash: string, cppData: Record<string, string>): Promise<void> {
    // console.log(companyID, patientHash, cppData)
    console.log('saving company cpp data');

    await this.http
      .post(
        this.saveCPPToNonUserPatientURL,
        JSON.stringify({
          companyID,
          patientHash,
          cppData,
        }),
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * @firestore Firestore migration
   */
  getPatientData(userId: string): Observable<PatientUserData> {
    // TODO add strict typing
    return new Observable((observer) => {
      return firebase
        .firestore()
        .collection('patientUsers')
        .doc(userId)
        .onSnapshot({
          next: (snapshot) => {
            observer.next(snapshot.data() as PatientUserData);
          },
          error: (error) => {
            console.error(error);
            observer.error(error);
          },
          complete: () => {
            observer.complete();
          },
        });
    });
  }

  async createPhysioPatientAccount(
    signUpForm: UntypedFormGroup,
    referrerID: string,
  ): Promise<{ valid: boolean, message?: string, email?: string, password?: string }> {
    const appointmentConfig = { medium: 'inPerson' };
    return await this.createPatientAccountFromForm(
      signUpForm,
      appointmentConfig,
      this.physioInfo[referrerID],
    );
  }

  getPhysioReferralData(referrerId: string): Record<string, never> | PhysioInfo {
    return this.physioInfo[referrerId];
  }

  getPatientRegistrationFromForm(
    signUpForm: UntypedFormGroup,
  ): PatientRegistration {
    const formValidity = this.checkFormValid(signUpForm, { medium: 'inPerson' });
    if (!formValidity.valid) {
      throw new Error(formValidity.message);
    }

    return {
      firstName: signUpForm.controls.first.value,
      lastName: signUpForm.controls.last.value,
      email: signUpForm.controls.email.value,
      password: signUpForm.controls.password.value,
      birthdate: signUpForm.controls.birthdate.value,
      gender: signUpForm.controls.gender.value,
      address: signUpForm.controls.address.value,
      city: signUpForm.controls.city.value,
      province: signUpForm.controls.province.value,
      country: signUpForm.controls.country.value,
      postalCode: signUpForm.controls.postalCode.value,
      phone: signUpForm.controls.phone.value,
      healthcardNumber: signUpForm.controls.healthCardNumber.value,
      pharmacyName: signUpForm.controls.pharmaName.value,
      pharmacyFax: signUpForm.controls.pharmaFax.value,
      acceptTerms: signUpForm.controls.terms.value,
      lastAcceptedTermsOfUseVersion: PatientTermsOfUseComponent.VERSION,
      familyDoctorFaxNumber: signUpForm.controls.familyDoctorFaxNumber.value,
      familyDoctorFullName: signUpForm.controls.familyDoctorFullName.value,
      isExpresslyOptingInToPromotionalEmails: signUpForm.controls.promo.value,
    };
  }

  async createPatientAccountFromForm(
    signUpForm: UntypedFormGroup,
    appointmentConfig = { medium: 'inPerson' },
    extraPatientInfo?: PhysioInfo,
    pharmacyNotRequired?: boolean,
    confirmsNotRequired?: boolean,
  ): Promise<{ valid: boolean, message?: string, email?: string, password?: string }> {
    const check = this.checkFormValid(
      signUpForm,
      appointmentConfig,
      pharmacyNotRequired,
      confirmsNotRequired,
    );
    if (!check.valid) {
      return { valid: false, message: check.message };
    }
    let patientUserData: {
      lastAcceptedTermsOfUseVersion?: string;
      tiaTermsVer2?: number;
      email?: string;
      password?: string;
    } = {};
    for (const field of Object.keys(signUpForm.controls || {})) {
      patientUserData[field] = signUpForm.controls[field].value;
    }

    if (!!signUpForm.controls['birthdate'] && !!signUpForm.controls['birthdate'].value) {
      const [month, day, year] = signUpForm.controls['birthdate'].value.split('/');
      patientUserData['year'] = year;
      patientUserData['day'] = day;
      patientUserData['month'] = month;
    }

    if (extraPatientInfo) {
      patientUserData = Object.assign(extraPatientInfo, patientUserData);
    }

    patientUserData.lastAcceptedTermsOfUseVersion = PatientTermsOfUseComponent.VERSION;
    patientUserData.tiaTermsVer2 = new Date().getTime();
    const email = patientUserData.email;
    const password = patientUserData.password;
    delete patientUserData['password'];
    delete patientUserData['passwordConfirm'];
    delete patientUserData['emailConfirm'];

    let result;
    try {
      result = await this.createPatientAccount(
        patientUserData,
        signUpForm.controls['password'].value,
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
    console.log(result);
    const jsonResult = result.body;
    console.log(jsonResult);
    if (!jsonResult.code) {
      return { valid: true, email, password };
    } else {
      return { valid: false, message: jsonResult.message };
    }
  } // end func

  checkFormValid(
    form: UntypedFormGroup,
    appointmentConfig: { medium: string; },
    pharmacyNotRequired?: boolean,
    confirmsNotRequired?: boolean,
  ): { valid: boolean, message?: string } {
    console.log(pharmacyNotRequired);
    if (form.controls['year'].value) {
      form.controls['year'].setValue(
        form.controls['year'].value.replace(/[^\d]/g, ''),
      );
    }
    if (form.controls['month'].value) {
      form.controls['month'].setValue(
        form.controls['month'].value.replace(/[^\d]/g, ''),
      );
    }
    if (form.controls['day'].value) {
      form.controls['day'].setValue(
        form.controls['day'].value.replace(/[^\d]/g, ''),
      );
    }
    let message = '';
    if (!form.controls['first'].valid) {
      message = 'Please enter your first name';
    } else if (!form.controls['last'].valid) {
      message = 'Please enter your last name';
    } else if (!form.controls['email'].valid) {
      message = 'Please enter a valid email';
    } else if (
      !confirmsNotRequired &&
      !!form.controls['emailConfirm'] && (
        !form.controls['emailConfirm'].valid ||
        form.controls['email'].value !== form.controls['emailConfirm'].value
      )
    ) {
      message = 'Email Confirm must match email';
    } else if (!form.controls['phone'].valid) {
      message = 'Please enter a valid phone number';
    } else if (
      // if birthdate exists, make sure its valid
      (!!form.controls['birthdate'] &&
        !form.controls['birthdate'].valid)
      ||
      // if birthdate does not exist, make sure all year/month/day fields are valid
      (
        !form.controls['birthdate'] &&
        (!form.controls['year'].valid ||
        !form.controls['month'].valid ||
        !form.controls['day'].valid)
      )
    ) {
      message = 'Please enter a valid birthdate';
    } else if (!form.controls['address'].valid) {
      message = 'Please enter a valid address';
    } else if (!form.controls['province'].valid) {
      message = 'Please enter a province';
    } else if (!form.controls['postalCode'].valid) {
      message = 'Please enter a valid postal code';
    } else if (!form.controls['city'].valid) {
      message = 'Please enter a city';
    } else if (!form.controls['gender'].valid) {
      message = 'Please enter your gender';
    } else if (!form.controls['pharmaName'].valid && !pharmacyNotRequired) {
      message = 'Please enter a pharmacy';
    } else if (
      appointmentConfig.medium !== 'inPerson' &&
      !form.controls['pharmaFax'].valid &&
      !pharmacyNotRequired
    ) {
      message = 'Please enter your pharmacy fax number';
    } else if (!form.controls['password'].valid) {
      message = 'Password must be at least 6 characters';
    } else if (!confirmsNotRequired
      && !!form.controls['passwordConfirm']
      && !form.controls['passwordConfirm'].valid) {
      message = 'Password Confirm must match password';
    } else if (!form.controls['terms'].valid) {
      message = 'You must accept the Terms & Conditions to continue';
    } else {
      // form is valid so return true
      return { valid: true };
    }
    return { valid: false, message };
  }

  getProvinces(): Province[] {
    return this.provinces;
  }

  async checkIfPatientIsInsured(idToken: string): Promise<boolean> {
    return this.http
      .post<boolean>(
        this.checkIfPatientIsInsuredUrl,
        { idToken },
        { headers: this.headers },
      )
      .pipe(take(1))
      .toPromise();
  }
}
