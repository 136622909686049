/// <reference types="@types/googlemaps" />

import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChildren,
  QueryList, inject } from '@angular/core';
import { UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PillwayInfoDialogComponent } from 'insig-app/global/dialogs/pillway-info-dialog/pillway-info-dialog.component';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { VirtualCareService } from 'insig-app/services/virtual-care/virtual-care.service';
import { PreferredPharmacyService } from 'insig-app/services/preferred-pharmacy.service';

import { PatientTermsDialogComponent } from '../dialogs/terms/patient-terms-dialog.component';

import { CookieService } from 'ngx-cookie-service';
import { RegexService } from '@insig-health/services/regex/regex.service';
import { LocationService, Region } from '@insig-health/services/location/location.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';
import { PatientProfile } from '@insig-health/services/patient-profile/patient-profile.service';
import { PatientRegistrationService } from '@insig-health/api/patient-registration-api';
import { firstValueFrom, Observable } from 'rxjs';
import { CpsEsDoctor } from '@insig-health/services/cps-es/cps-es.service';
import { PhoneFormatService } from '@insig-health/services/phone-format/phone-format.service';

@Component({
  selector: 'patient-sign-up',
  templateUrl: './patient-sign-up.component.html',
  styleUrls: ['./patient-sign-up.component.scss'],
  providers: [
    VirtualCareService,
    PatientUserDataService,
    PreferredPharmacyService,
    CookieService,
  ],
})
export class PatientSignUpComponent implements OnInit, AfterViewInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly insigDialog = inject(MatDialog);
  private readonly snackBar = inject(MatSnackBar);
  private readonly locationService = inject(LocationService);
  private readonly patientUserDataService = inject(PatientUserDataService);
  private readonly cookieService = inject(CookieService);
  public readonly preferredPharmacyService = inject(PreferredPharmacyService);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  private readonly googlePlaceService = inject(GooglePlaceService);
  private readonly patientRegistrationService = inject(PatientRegistrationService);
  private readonly phoneFormatService = inject(PhoneFormatService);
  public static readonly BIRTHDATE_FORMAT_ERROR_STRING = 'Birthdate must match format YYYY-MM-DD';
  public static readonly BIRTHDATE_INVALID_ERROR_STRING = 'Invalid birth date';

  private birthDateRegex = new RegExp(/^\d{4}\-\d{2}\-\d{2}$/);  

  public signUpForm: UntypedFormGroup;
  public canadianProvinces = this.patientUserDataService.provinces;
  public americanStates = this.patientUserDataService.americanStates;

  public nameQuery$: Observable<string> | undefined;
  public provinceQuery$: Observable<string> | undefined;

  @Input() hideFields: any = {};
  countryChanged(): void {
    // If the country changed reset the province field value
    this.signUpForm.controls.province.setValue(null);
  }
  public countryList = ['Canada', 'United States', 'Mexico', 'Grenada'];

  addressChanged(): void {
    this.patientInfo.city = this.signUpForm.controls.city.value;
    this.patientInfo.province = this.signUpForm.controls.province.value;
    this.patientInfo.address = this.signUpForm.controls.address.value;
    this.patientInfo.postalCode = this.signUpForm.controls.postalCode.value;
  }

  private today = new Date();

  public geolocatePharmacy: string | undefined;
  @Input() patientLoginSignUp = false;
  @Input() tiaHealth = false;
  @Input() pharmacyNotRequired = false;
  @Input() hidePharmacy = false;
  @Input() blockStyle = false;
  @Input() backButtonText = 'Back to Login';
  @Input() partialPatient: Partial<PatientProfile> | undefined;

  @Output() onSignUp = new EventEmitter();
  @Output() backToLogin = new EventEmitter();
  @Output() onProfileComplete = new EventEmitter();
  public defaultPharmacy: any = {};
  private region: Region | undefined;
  @Input() specialLogo = '';
  @Input() specialTitle = '';
  public patientInfo: any = {};

  @ViewChildren('searchAddressSignUp')
  public addressElementRefSignUp: QueryList<ElementRef> | undefined;

  constructor(
) {
    this.signUpForm = this.buildSignUpForm(this.partialPatient);
  }

  ngOnInit(): void {
    this.geolocatePharmacy = this.cookieService.get('geolocatePharmacy') ?? undefined;

    // hide map because 1 want specific pharmacy
    if (
      this.cookieService.get('pharmacyFax') ||
      this.cookieService.get('pharmacyName')
    ) {
      this.hidePharmacy = true;
    }

    this.getDefaultPharmacy();

    if (
      window.location.origin.includes('app.well.company') ||
      window.location.origin.includes('app.wellclinics.ca')
    ) {
      this.specialLogo = 'assets/images/global/well/well-logo-normal.svg';
      this.specialTitle = 'Well Health';
    } else if (window.location.origin.includes('app.jacknathanhealth.com')) {
      this.specialLogo = 'assets/images/global/jnh/jnh-logo.png';
      this.specialTitle = 'Jack Nathan Health';
      // JNH can't ever show another pharmacy
      // this.geolocatePharmacy = 'Walmart Pharmacy';
    } else if (window.location.origin.includes('app.thevirtualdoctor.org')) {
      this.specialLogo = 'assets/images/global/tvd/tvd.jpeg';
      this.specialTitle = 'The Virtual Doctor';
    } else if (window.location.origin.includes('virtual.highmark.tech')) {
      this.specialLogo = 'assets/images/global/eq/eq-logo.png';
      this.specialTitle = 'EQ Virtual';
    }
  }

  ngAfterViewInit(): void {
    console.log('after view init: ', this.addressElementRefSignUp);
    this.setupAddressAutocomplete();
    // set Canada as default country
    this.signUpForm.controls.country.setValue('Canada');
  }

  handleFamilyDoctorClicked(familyDoctor: CpsEsDoctor): void {
    this.signUpForm.get('familyDoctorFaxNumber')?.setValue(familyDoctor.faxNumber);
  }

  async getDefaultPharmacy(): Promise<void> {
    this.region = await this.locationService.getRegion();
    this.defaultPharmacy = this.preferredPharmacyService.getDefaultPharmacyObj(
      this.region
    );
    console.log('Found default pharmacy: ', this.defaultPharmacy);
  }

  setupAddressAutocomplete(): void {
    this.googlePlaceService.getPlaceResultObservable(this.addressElementRefSignUp?.first.nativeElement).subscribe((placeResult) => {
      console.log('place: ', placeResult);
      this.signUpForm.controls['address'].setValue(
        placeResult['formatted_address']
      );
      if (placeResult.address_components) {
        placeResult.address_components.forEach((item) => {
          if (item.types.includes('postal_code')) {
            this.signUpForm.controls['postalCode'].setValue(
              item.long_name
            );
          }
          if (item.types.includes('country')) {
            const foundCountry = this.countryList.find(
              (e) => e === item.long_name
            );
            if (foundCountry) {
              this.signUpForm.controls['country'].setValue(
                foundCountry
              );
            }
          }
          if (item.types.includes('administrative_area_level_1')) {
            this.signUpForm.controls['province'].setValue(
              item.short_name
            );
          }
          if (item.types.includes('locality')) {
            this.signUpForm.controls['city'].setValue(item.long_name);
          }
        });
      }
      this.addressChanged();
    });
  }

  setPreferredPharmacy(selectedPharmacy: { name: string; fax: string }): void {
    console.log(selectedPharmacy);
    this.signUpForm.controls.pharmaName.setValue(selectedPharmacy.name);
    this.signUpForm.controls.pharmaFax.setValue(this.phoneFormatService.getE164PhoneNumberEvenIfInvalid(selectedPharmacy.fax));
  }

  buildSignUpForm(partialPatient: Partial<PatientProfile> | undefined): UntypedFormGroup {
    // setting preferred pharmacy for pharma partners
    const pharmacyFax = this.cookieService.get('pharmacyFax') || null;
    const pharmacyName = this.cookieService.get('pharmacyName') || null;

    return this.formBuilder.group(
      {
        first: this.formBuilder.control(partialPatient?.firstName, [Validators.required]),
        last: this.formBuilder.control(partialPatient?.lastName, [Validators.required]),
        email: this.formBuilder.control(partialPatient?.email, [Validators.required]),
        phone: this.formBuilder.control(partialPatient?.phone, [Validators.required]),
        pharmaFax: this.formBuilder.control(pharmacyFax, [Validators.required]),
        extension: this.formBuilder.control(null),
        healthCardNumber: this.formBuilder.control(null),
        pharmaName: this.formBuilder.control(pharmacyName, [
          Validators.required,
        ]),
        address: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(2),
        ]),
        country: this.formBuilder.control(null, [Validators.required]),
        province: this.formBuilder.control(null, [Validators.required]),
        city: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(2),
        ]),
        postalCode: this.formBuilder.control(null, [
          Validators.minLength(5),
          Validators.maxLength(7),
        ]),
        birthdate: this.formBuilder.control(null, [
          Validators.required,
        ]),
        year: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1900),
          Validators.max(this.today.getFullYear()),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        month: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        day: this.formBuilder.control(null, [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        gender: this.formBuilder.control(null, [Validators.required]),
        terms: this.formBuilder.control(false, [Validators.requiredTrue]),
        promo: this.formBuilder.control(false),
        password: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
        familyDoctorFullName: this.formBuilder.control(null),
        familyDoctorFaxNumber: this.formBuilder.control(null),
      },
      {
        validators: [
          this.checkBirthDateValid('birthdate')
        ],
      }
    );
  }

  checkBirthDateValid(birthdate: string): (group: UntypedFormGroup) => void {
    return (group: UntypedFormGroup) => {
      const birthdateInput = group.controls[birthdate];
      if (!birthdateInput.value) {
        return birthdateInput.setErrors({'incorrect': true});
      }
      let [year, month, day] = birthdateInput.value.split('-');
      if (year === undefined || month === undefined || day === undefined) {
        return birthdateInput.setErrors({'incorrect': true});
      }

      if (day.length !== 2) {
        return birthdateInput.setErrors({'incorrect': true});
      }

      if (month < 1 || month > 12) {
        return birthdateInput.setErrors({'incorrect': true});
      }
      if (day < 1 || day > 31) {
        return birthdateInput.setErrors({'incorrect': true});
      }
      if (year < 1900 || year > this.today.getFullYear()) {
        return birthdateInput.setErrors({'incorrect': true});
      }
      return birthdateInput.setErrors(null);
    };
  }

  async triggerPatientTerms(): Promise<void> {
    console.log('adding new category');
    const dialogRef = this.insigDialog.open(PatientTermsDialogComponent);

    const selection = await firstValueFrom(dialogRef.afterClosed());
    if (selection) {
      this.signUpForm.controls.terms.setValue(true);
    }
  }

  useDefaultPharmacy(): void {
    this.signUpForm.controls.pharmaName.setValue(
      this.defaultPharmacy.pharmaName
    );
    this.signUpForm.controls.pharmaFax.setValue(this.defaultPharmacy.pharmaFax);
    this.snackBar.open('Pharmacy selected!', undefined, { duration: 5000 });
    this.hidePharmacy = true;
  }

  openPillwayInfo(): void {
    this.insigDialog.open(PillwayInfoDialogComponent, {
      autoFocus: 'button',
    });
  }

  handleSignUpButtonClicked(signUpForm: UntypedFormGroup): void {
    const birthDateFieldValue = this.formatBirthdateString(signUpForm.controls.birthdate.value as string);

    if (!this.birthDateRegex.test(birthDateFieldValue)) {
      this.snackBar.open('Birthdate must match format YYYY-MM-DD', undefined, { duration: 5000 });
      return;
    }

    if (!this.isBirthDateValid(birthDateFieldValue)) {
      this.snackBar.open('Invalid birth date', undefined, { duration: 5000 });
      return;
    }

    this.onSignUp.emit(this.signUpForm);
  }

  async handleCompleteSignUpButtonClicked(signUpForm: UntypedFormGroup): Promise<void> {
    const birthDateFieldValue = this.formatBirthdateString(signUpForm.controls.birthdate.value as string);
      
    if (!this.birthDateRegex.test(birthDateFieldValue)) {
      this.snackBar.open(PatientSignUpComponent.BIRTHDATE_FORMAT_ERROR_STRING, undefined, { duration: 5000 });
      return;
    }

    if (!this.isBirthDateValid(birthDateFieldValue)) {
      this.snackBar.open(PatientSignUpComponent.BIRTHDATE_INVALID_ERROR_STRING, undefined, { duration: 5000 });
      return;
    }

    const controls = signUpForm.controls;
    const patientRegistration = {
      birthdate: birthDateFieldValue,
      gender: controls.gender.value,
      address: controls.address.value,
      city: controls.city.value,
      province: controls.province.value,
      country: controls.country.value,
      postalCode: controls.postalCode.value,
      healthcardNumber: controls.healthCardNumber.value,
      pharmacyName: controls.pharmaName.value,
      pharmacyFax: controls.pharmaFax.value,
      familyDoctorFullName: controls.familyDoctorFullName.value,
      familyDoctorFaxNumber: controls.familyDoctorFaxNumber.value,
      isExpresslyOptingInToPromotionalEmails: controls.promo.value
    };
    await firstValueFrom(this.patientRegistrationService.updatePatientRegistration(patientRegistration));
    this.onProfileComplete.emit();
  }

  isBirthDateValid(birthDateString: string): boolean {
    const [year, month, day] = birthDateString.split('-');

    const paddedMonth = (month.length < 2) ? `0${month}` : month;
    const paddedDay = (day.length < 2) ? `0${day}` : day;

    const combinedBirthDateFields = `${year}-${paddedMonth}-${paddedDay}`;
    return this.dateAndTimeService.isDateValid(combinedBirthDateFields, 'YYYY-MM-DD');
  }

  formatBirthdateString(birthdateString: string): string {
    if (birthdateString === null || birthdateString === undefined) {
      return '';
    }

    const digitsString = birthdateString.trim().replace(RegexService.NON_DIGIT_GLOBAL_REGEX, '');
    const year = digitsString.substring(0, 4);
    const month = digitsString.substring(4, 6);
    const day = digitsString.substring(6, 8);

    if (month === '') {
      return year;
    } else if (day === '') {
      return `${year}-${month}`;
    } else {
      return `${year}-${month}-${day}`;
    }    
  }

  handleBirthdateFieldChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.signUpForm.controls.birthdate.setValue(this.formatBirthdateString(inputElement.value));
    this.signUpForm.controls.birthdate.updateValueAndValidity();
  }

}
