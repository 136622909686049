import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { CompanyData } from 'insig-types/company-data/index';
import { DoctorScheduleDataESService } from '@insig-health/api/doctor-booking-flow-api-v1';
import { CompanyManagementService, CompanyUpdateRequest as ApiCompanyUpdateRequest } from '@insig-health/api/company-management-api';

export type CompanyUpdateRequest = ApiCompanyUpdateRequest;

@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {
  private readonly http = inject(HttpClient);
  private readonly doctorScheduleDataESService = inject(DoctorScheduleDataESService);
  private readonly companyManagementService = inject(CompanyManagementService);
  private getCompanyDataUrl = JAVA_BACKEND_ENDPOINT + 'company/';
  private headers = new HttpHeaders({});

  getCompanyData(companyId: string): Promise<CompanyData> {
    return firstValueFrom(this.http
      .get<CompanyData>(this.getCompanyDataUrl + companyId, {
        headers: this.headers,
      }),
    ).catch((error) => {
      console.error(error);
      throw error;
    });
  }

  reindexCompany(companyId: string): Promise<void> {
    return lastValueFrom(this.doctorScheduleDataESService.addCompanyDoctorsSchedules(companyId));
  }

  async updateCompany(companyId: string, companyUpdateRequest: CompanyUpdateRequest): Promise<void> {
    await lastValueFrom(this.companyManagementService.updateCompany(companyId, companyUpdateRequest));
  }

  updateCompanyPublicBillingPaymentMethod(companyId: string, stripePaymentSourceToken: string): Promise<void> {
    return lastValueFrom(this.companyManagementService.updateCompanyPublicBillingPaymentMethod(companyId, {
      paymentSourceToken: stripePaymentSourceToken,
    }));
  }
}
