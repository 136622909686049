import { Injectable } from '@angular/core';
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';

@Injectable({
  providedIn: 'root',
})
export class PhoneFormatService {
  public getE164PhoneNumber(phoneNumber: string): string {
    let parsedPhoneNumber;
    if (phoneNumber.includes('+')) {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } else {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber, { regionCode: 'US' });
    }

    if (parsedPhoneNumber.valid) {
      return parsedPhoneNumber.number.e164;
    } else {
      throw new Error('Invalid phone number: ' + parsedPhoneNumber.possibility);
    }
  }

  public getE164PhoneNumberEvenIfInvalid(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }

    let parsedPhoneNumber;
    if (phoneNumber.includes('+')) {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } else {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber, { regionCode: 'US' });
    }

    return parsedPhoneNumber.number?.e164 ?? phoneNumber;
  }

  public getNationalFormattedPhoneNumber(phoneNumber?: string): string {
    let parsedPhoneNumber: ParsedPhoneNumber | undefined = undefined;
    if (!phoneNumber) {
      return '';
    } else if (phoneNumber.startsWith('+')) {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } else {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber, { regionCode: 'CA' });
    }
    return parsedPhoneNumber.valid ? parsedPhoneNumber.number.national : phoneNumber;
  }

  public getSignificantDigitsFormattedPhoneNumber(phoneNumber?: string): string {
    let parsedPhoneNumber: ParsedPhoneNumber | undefined = undefined;
    if (!phoneNumber) {
      return '';
    } else if (phoneNumber.startsWith('+')) {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } else {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber, { regionCode: 'CA' });
    }
    return parsedPhoneNumber.valid ? parsedPhoneNumber.number.significant : phoneNumber;
  }
}
