// @ts-strict-ignore
import {
  Component,
  ChangeDetectorRef,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { CompanyLocationService } from 'insig-app/services/company/location.service';
import { SaveNoteService } from 'insig-app/services/saveNote.service';
import { SpecialResponseService } from 'insig-app/services/specialResponse.service';
import { InitNoteService } from 'insig-app/services/initNote.service';
import { InProgressSurveyService } from 'insig-app/services/inProgressSurvey.service';
import { SendEmailService } from 'insig-app/services/sendEmail.service';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { VirtualCareService } from 'insig-app/services/virtual-care/virtual-care.service';
import { GeolocationService } from 'insig-app/services/geolocation.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { CHSService } from 'insig-app/services/emr/chs.service';
import { EMRService } from 'insig-app/services/emr/emr.service';
import { NotesService } from 'insig-app/services/notes.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from 'insig-app/services/googleAnalytics.service';
import { GoogleTagManagerService } from 'insig-app/services/googleTagManager.service';
import { RegexService } from '@insig-health/services/regex/regex.service';
import firebase from 'firebase/compat/app';

import { SubmitSurveyService } from '../services/submit-survey.service';

import printJS from 'print-js';
import { CookieService } from 'ngx-cookie-service';
import * as LZString from 'lz-string';

import { SpringNote, NoteAndPatientResponse, PatientInfo } from 'insig-types/spring-api/notes';
import { PRODUCTION } from '@insig-health/config/config';
import { take } from 'rxjs/operators';
import { PatientUserData } from 'insig-types/user-data';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { firstValueFrom } from 'rxjs';
import { Page } from '@insig-health/insig-types/surveys';
import { UtilitiesService } from '@insig-health/services/utilities/utilities.service';

const RESUBMIT_SURVEY_COOLDOWN_MS = 10000;

const YEAR_REGEX = new RegExp(/^(19|20)[0-9]{2}$/);
const MONTH_REGEX = new RegExp(/^([1-9]|1[0-2])$/);
const DAY_REGEX = new RegExp(/^([1-9]|[12]\d|3[01])$/);

@Component({
  selector: 'launch-survey',
  templateUrl: './launch-survey.component.html',
  styles: [
    `
      .backgroundBlue {
        background-image: linear-gradient(
          to bottom,
          rgba(33, 150, 255, 0.1),
          transparent
        );
      }

      *:not(html) {
        -webkit-transform: translate3d(0, 0, 0);
      }
    `,
  ],
  providers: [
    LoadSurveyService,
    PatientUserDataService,
    CHSService,
    SaveNoteService,
    EMRService,
    SubmitSurveyService,
    GoogleAnalyticsService,
    InitNoteService,
    SpecialResponseService,
    NotesService,
    SendEmailService,
    TranslateService,
    VirtualCareService,
    CompanyLocationService,
    GoogleTagManagerService,
    RegexService,
  ],
})
export class LaunchSurveyComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly loadSurveyService = inject(LoadSurveyService);
  private readonly submitSurveyService = inject(SubmitSurveyService);
  private readonly router = inject(Router);
  private readonly cHSService = inject(CHSService);
  private readonly notesService = inject(NotesService);
  private readonly patientUserDataService = inject(PatientUserDataService);
  private readonly initNoteService = inject(InitNoteService);
  private readonly inProgressSurveyService = inject(InProgressSurveyService);
  private readonly sendEmailService = inject(SendEmailService);
  private readonly specialResponseService = inject(SpecialResponseService);
  public readonly cookieService = inject(CookieService);
  private readonly snackbar = inject(MatSnackBar);
  public readonly translateService = inject(TranslateService);
  private readonly geolocationService = inject(GeolocationService);
  private readonly apollo = inject(Apollo);
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly companyLocationService = inject(CompanyLocationService);
  private readonly googleAnalyticsService = inject(GoogleAnalyticsService);
  private readonly firebaseAuthService = inject(FirebaseAuthService);
  private readonly regexService = inject(RegexService);
  private readonly dateAndTimeService = inject(DateAndTimeService);
  private readonly utilitiesService = inject(UtilitiesService);
  // graphql queries
  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        name
        showAds
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
        branding
        brandingName
      }
    }
  `;

  //

  // save progress if changing window (eg clicking an ad)
  @HostListener('document:visibilitychange', ['$event']) handleVisibilityChange(
    _event: Event
  ) {
    // shouldn't save for any of these situations
    if (
      this.preview ||
      this.quickView ||
      !!this.patientInfoFromScheduler ||
      this.schedulerLogin ||
      this.checkInMode === 'true' ||
      this.patientInfoAutopopulate ||
      this.surveySubmitted ||
      !this.formSubmitted
    ) {
      // nothing, shouldn't store info
    } else {
      if (document.visibilityState === "hidden") {
        this.setPtCookie();
        this.inProgressSurveyService.saveInProgressSurvey({
          doctorId: this.userID,
          surveyId: this.surveyID,
        }, this.survey, this.currentPage);
      }
    }
  }

  // expire in 5 minutes
  public baseURL = window.location.host;
  public questionnaireAlreadyCompleted = false;
  private EXPIRECOOKIE = 60000 * 5;
  public currentPage = 0;
  private patientInfoAutopopulate = false;
  private surveyID = null;
  private userID = null;
  private companyID = null;
  private companyData: any = {};
  private surveyType = null;
  @Input() survey = null;
  public showAds = false;
  // comes from patient-login component
  @Input() patientInfo: any = {};
  public surveyLoaded = false;
  private submittingSurvey = false;
  public formSubmitted = false;
  public surveySubmitted = false;
  @Input() schedulerLogin = false;
  public checkInMode = 'false';
  private clinicEmail: string;
  public preview = false;
  public locationID: string;
  @Input() editingResponses = false;
  @Input() editingSurveyCompanyId: string;
  @Input() patientInfoFromScheduler: PatientInfo = undefined;
  private patientInfoFromCookie = false;
  @Input() quickView: any;
  @Output() schedulerSubmitSurvey: EventEmitter<string> = new EventEmitter();
  @Output() checkBrokenSurvey: EventEmitter<boolean> = new EventEmitter();
  private errorSubmitting = false;
  // apptID is linkID without the companyID at the front
  public apptID: string | undefined;
  private EMRapptID = null;
  public sentInAppt = null;
  public surveyError = false;
  public paramsLoaded = false;
  public showSurvey = false;
  public bypassPatientInfoForm = false;
  @Input() tempSurveyLoaded = false;
  private startTime = new Date().toISOString();
  public uploadedPDFIDs = {}; // base64 versions of PDFs for unauthenticated local read/write

  @Input() lockNote = false; // Whether or not the note should be locked
  @Input() familyId?: string; // The id of the family member the survey is being submitted for, if any

  @Input() hideNavigationButton = false;

  private _resubmitTimer: NodeJS.Timeout | null;
  public get resubmitOnCooldown(): boolean {
    return !!this._resubmitTimer;
  }
  public set resubmitOnCooldown(value: boolean) {
    if (value === true && !this._resubmitTimer) {
      this._resubmitTimer = global.setTimeout(() => {
        if (this._resubmitTimer) {
          global.clearTimeout(this._resubmitTimer);
          this._resubmitTimer = null;
        }
      }, RESUBMIT_SURVEY_COOLDOWN_MS);
    } else if (value === false) {
      if (this._resubmitTimer) {
        global.clearTimeout(this._resubmitTimer);
        this._resubmitTimer = null;
      }
    }
  }

  // endpoints
  async ngOnInit(): Promise<void> {
    await this.loadQueryParams();

    console.log('after query par');

    if (!this.patientInfoAutopopulate) {
      this.patientInfo = await this.getPatientInfo();
    }

    // if quickview just load survey
    if (this.quickView) {
      this.companyID = this.quickView.companyID;
      this.userID = this.quickView.userID;
      this.surveyType = this.quickView.type;
      this.surveyID = this.quickView.surveyID;
      await this.loadSurvey(this.surveyType, this.userID, this.surveyID);
    } else {
      const params = this.route.snapshot.parent?.paramMap.get('userID') ? this.route.snapshot.parent.params : this.route.snapshot.params;
      console.log('params: ', params);
      this.companyID = this.companyID ?? params['companyID'];
      this.userID = params['userID'];
      this.surveyType = params['type'];
      // Here is where the surveyID is set
      this.surveyID = params['surveyID'];
      if (params['moreOptions'] === 'preview') {
        this.preview = true;
      }
      // load data
      this.reloadPtInfoFromCookie();
      // survey loaded from cookie caseID
      if (this.tempSurveyLoaded) {
        this.surveyLoaded = true;
        if (
          !!this.patientInfoFromScheduler ||
          this.patientInfoFromCookie ||
          this.patientInfoAutopopulate
        ) {
          this.formSubmitted = true;
        }
      } else if (this.editingResponses) {
        this.surveyLoaded = true;
        this.formSubmitted = true;
        if (this.survey) {
          this.survey.caseID = null;
          delete this.survey.caseID;
        }
        this.companyID = this.survey.companyID || this.editingSurveyCompanyId;
      } else {
        await this.loadSurvey(this.surveyType, this.userID, this.surveyID);
      }
      await this.checkCompanyID();

      // check if questionnaire already completed for appt
      if (this.apptID && !this.sentInAppt) {
        const questionnaireAlreadyCompleted = await this.notesService.checkIfQuestionnaireCompleted(
          this.companyID,
          this.apptID
        );
        console.log(questionnaireAlreadyCompleted);
        if (questionnaireAlreadyCompleted) {
          this.questionnaireAlreadyCompleted = questionnaireAlreadyCompleted;
        }
      } // end if
    } // end else

    // save time they started questionnaire after its loaded
    this.startTime = new Date().toISOString();
  } // end ngOnInit

  async getPatientInfo(): Promise<PatientInfo> {
    const user = await this.getUser();
    const params = this.route.snapshot.queryParams;

    // load patient info passed in if it is passed in
    if (!!this.patientInfoFromScheduler) {
      return this.patientInfoFromScheduler as PatientInfo;
    }

    this.bypassPatientInfoForm =
      this.isManualAppointment(this.apptID) &&
      await this.loggedInAsPatient(user);

    if (this.bypassPatientInfoForm) {
      return await this.getFirebasePatientData(user) as PatientInfo;
    }

    if (this.isLoggedInWithUid(user, params.uid)) {
      if (!!this.familyId) {
        const familyMember = await this.patientUserDataService.getOneFamilyMember(this.familyId).then((documentSnapshot) => documentSnapshot.data()?.data);
        const mainAccountProfile = await this.getFirebasePatientData(user) as PatientInfo;
        familyMember.pharmaFax = mainAccountProfile.pharmaFax;
        familyMember.pharmaName = mainAccountProfile.pharmaName;
        return familyMember as PatientInfo;
      } else {
        return await this.getFirebasePatientData(user) as PatientInfo;
      }
    }

    return {} as PatientInfo;
  }

  getUser(): Promise<firebase.User | null> {
    return this.firebaseAuthService
      .onIdTokenChanged()
      .pipe(take(1))
      .toPromise();
  }

  loadQueryParams(): Promise<any> {
    // get apptID if virtual care survey
    return new Promise((resolve, _reject) => {
      this.route.queryParams.subscribe(async (params) => {
        console.log(params);
        if (params.familyID) {
          this.familyId = params.familyID;
        }
        this.apptID = params['apptID'];
        this.sentInAppt = params['sia'];
        if (params['enc'] && params['iv']) {
          await this.autopopulateCHSPtInfo(params['enc'], params['iv']);
        } else if (params['ptInfo']) {
          await this.autopopulateLinkPtInfo(params['ptInfo']);
        } // end if else

        if (params.locationID) {
          this.locationID = params.locationID;
        }

        if (params.clinicEmail) {
          this.clinicEmail = params.clinicEmail;
        }
        this.paramsLoaded = true;
        resolve(true);
      });
    });
  }

  addCPPAndGoverningLawAgreementPage() {
    // Initialize the new page with the CPP questions
    const newPage: Page = {
      elements: [
        {
          cpp: true,
          id: this.utilitiesService.getRandomAlphanumerics(32),
          integrationID: 'heightWeight',
          note: {
            bullet: true,
            location: 'Patient Profile',
          },
          orderNo: 1,
          question: {
            id: this.utilitiesService.getRandomAlphanumerics(32),
            pageFlowModifier: false,
            required: false,
            text: 'Please confirm your current height and weight',
            type: 'heightWeight',
          },
          type: 'question',
        },
        {
          cpp: true,
          first: '%%-his-her-c is currently taking ',
          id: this.utilitiesService.getRandomAlphanumerics(32),
          note: {
            first: 'Current medications: ',
            location: 'Medications',
          },
          orderNo: 2,
          question: {
            allowMultiple: true,
            id: this.utilitiesService.getRandomAlphanumerics(32),
            pageFlowModifier: false,
            required: false,
            text: 'Please list all of your current medications',
            type: 'medications',
          },
          type: 'question',
        },
        {
          cpp: true,
          id: this.utilitiesService.getRandomAlphanumerics(32),
          note: {
            location: 'Past Medical History',
          },
          orderNo: 3,
          question: {
            id: this.utilitiesService.getRandomAlphanumerics(32),
            pageFlowModifier: false,
            required: false,
            text: 'Medical Conditions',
            type: 'conditions',
          },
          type: 'question',
        },
        {
          cpp: true,
          id: this.utilitiesService.getRandomAlphanumerics(32),
          integrationID: 'allergies',
          note: {
            location: 'Medication Allergies',
          },
          orderNo: 4,
          question: {
            id: this.utilitiesService.getRandomAlphanumerics(32),
            pageFlowModifier: false,
            required: false,
            text: 'Medication Allergies',
            type: 'medicationAllergies',
          },
          type: 'question',
        },
        {
          id: this.utilitiesService.getRandomAlphanumerics(32),
          orderNo: 5,
          question: {
            id: this.utilitiesService.getRandomAlphanumerics(32),
            pdfType: 'patient',
            required: false,
            text: 'Optional: Upload PDF documents related to your visit',
            type: 'pdf',
          },
          type: 'question',
        },
        {
          id: this.utilitiesService.getRandomAlphanumerics(32),
          orderNo: 6,
          note: {
            location: 'HPI',
          },
          question: {
            id: this.utilitiesService.getRandomAlphanumerics(32),
            required: false,
            text: 'Optional: Add a photo related to your visit',
            type: 'photograph',
          },
          type: 'question',
        },
      ],
      id: this.utilitiesService.getRandomAlphanumerics(32),
    };

    // Insert Governing Law and Jurisdiction Agreement for Tia patients
    if (this.companyID === "tiaHealth") {
      newPage.elements.push({
        id: this.utilitiesService.getRandomAlphanumerics(32),
        note: {
          location: 'HPI',
          qa: true,
        },
        orderNo: 7,
        question: {
          id: this.utilitiesService.getRandomAlphanumerics(32),
          required: false,
          survId: 'Mt6on6095KJVWseAesl5z3U3vHNjSKhc',
          survName: 'Governing Law and Jurisdiction Agreement',
          text: 'Governing Law and Jurisdiction Agreement',
          triggeredLibrary: true,
          type: 'survey',
        },
        type: 'question',
      });
    }

    // Insert the new page, after index
    this.survey.pages.push(newPage);
  }

  addFeedbackQuestionsAndAds() {
    this.googleAnalyticsService.startAnalytics();
    this.googleAnalyticsService.googleEvent('adShown', { cid: this.companyID });
    const adsQuestionnaire = {
      type: 'question',
      question: {
        survUID: 'e8RxqbN61LOvCtjK2RYu20cJay32',
        id: '8mouTidNdFc9U0hB2lkWgrnoW4KjuEnk',
        required: false,
        type: 'survey',
        allowMultiple: null,
        text: 'Questionnaire Link',
        survId: 'H7WmHHEQoZgHApyzv1YbenmWyZWpQrKw',
        survName: 'Insig Education Questionnaire',
      },
      id: 'UDwgDRcWrKSOCODwsHW31EH9WJGPOjSG',
      orderNo: 1,
      note: { qa: true, location: 'HPI' },
    };
    // if apptID, add it to last page because already addding upload document page
    if (this.apptID) {
      this.survey.pages[this.survey.pages.length - 1].elements.unshift(
        adsQuestionnaire
      );
    } else {
      // Insert the new page, after index
      const newPage = {
        elements: [adsQuestionnaire],
        id: this.utilitiesService.getRandomAlphanumerics(32),
      };
      this.survey.pages.push(newPage);
    }
  } // end func

  // ensure company exists so results are saved to company
  async checkCompanyID(): Promise<any> {
    console.log('companyID: ', this.companyID);
    try {
      const companyDataQuery: any = await this.apollo
        .query({
          query: this.companyDataQuery,
          variables: {
            companyID: this.companyID,
            token: await this.firebaseAuthService.getIdToken(),
          },
        })
        .toPromise();
      const companyData = companyDataQuery.data.getCompanyData;
      if (!companyData) {
        console.log('Error loading company data');
        return false;
      }
      this.companyData = companyData;

      if (
        !!this.companyData &&
        this.companyID === 'tiaHealth' &&
        (window.location.origin.includes('app.wellclinics.ca') ||
          window.location.origin.includes('app.well.company'))
      ) {
        this.companyData.branding =
          'assets/images/global/well/well-logo-normal.svg';
        this.companyData.name = 'WELL Health';
      } else if (
        !!this.companyData &&
        this.companyID === 'tiaHealth' &&
        window.location.origin.includes('app.jacknathanhealth.com')
      ) {
        this.companyData.branding = 'assets/images/global/jnh/jnh-logo.png';
        this.companyData.name = 'Jack Nathan Health';
      } else if (
        !!this.companyData &&
        this.companyID === 'tiaHealth' &&
        window.location.origin.includes('app.thevirtualdoctor.org')
      ) {
        this.companyData.branding = 'assets/images/global/tvd/tvd.jpeg';
        this.companyData.name = 'The Virtual Doctor';
      } else if (
        !!this.companyData &&
        this.companyID === 'tiaHealth' &&
        window.location.origin.includes('virtual.highmark.tech')
      ) {
        this.companyData.branding = 'assets/images/global/eq/eq-logo.png';
        this.companyData.name = 'EQ Virtual';
      } // end if

      this.showAds = companyData.showAds;

      console.log(companyData);
    } catch (error) {
      console.error(error);
      console.log('Error loading company data');
      this.router.navigate(['/auth/404']);
      return false;
    }
  }

  getFirebasePatientData(user: firebase.User): Promise<PatientUserData> {
    return this.patientUserDataService.getPatientData(user.uid)
      .pipe(take(1))
      .toPromise();
  }

  async getLocation(): Promise<void> {
    try {
      const location = await firstValueFrom(this.geolocationService.getLocation());
      this.survey.coordinates = {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      };
    } catch (error) {
      // permission to access geolocation services was denied, ignore and continue
    }
  }

  async setupSurvey() {
    const surveyName = this.survey.name;
    this.survey.surveyNames = [
      {
        name: surveyName,
        id: this.survey.id,
        survUID: this.userID,
        type: this.survey.type,
        triage:
          this.survey.triage || this.survey.folder === 'triage' ? true : false,
      },
    ];

    try {
      if (this.apptID) {
        this.addCPPAndGoverningLawAgreementPage();
      }
      await this.checkCompanyID();
      if (this.showAds && !this.preview && PRODUCTION) {
        this.addFeedbackQuestionsAndAds();
      }
    } catch (err) {
      console.log(err);
    } // end try-catch

    this.submitSurveyService.setupTriggersDictionary(this.survey);
    this.surveyLoaded = true;
    if (
      !!this.patientInfoFromScheduler ||
      this.patientInfoFromCookie ||
      this.patientInfoAutopopulate
    ) {
      this.formSubmitted = true;
    }
    this.getLocation();
    this.changeDetector.detectChanges();
    return this.survey;
  }

  async loadSurvey(type, userID, surveyID): Promise<any> {
    if (type === 'user') {
      try {
        const survey = await this.loadSurveyService.getUserSurveyFromFirestore(userID, surveyID);
        console.log(survey);
        if (!survey.id) {
          throw new Error('Survey not found');
        }
        console.log(new Error('Survey was set: 1'));
        this.survey = survey;
        this.survey.type = 'user';
        await this.setupSurvey();
      } catch (error) {
        console.log(error);
        console.log('Error loading survey');
        this.surveyError = true;
        this.checkBrokenSurvey.emit(true);
        console.log('BROKEN SURVEY');
        return false;
      }
    } else if (type === 'library') {
      try {
        const survey = await this.loadSurveyService.getLibrarySurveyFromFirestore(surveyID)
        if (!survey.id) {
          throw new Error('Survey not found');
        }
        console.log(new Error('Survey was set: 2'));
        this.survey = survey;
        this.survey.type = 'library';
        await this.setupSurvey();
      } catch (error) {
        console.log(error);
        console.log('Error loading survey');
        this.surveyError = true;
        this.checkBrokenSurvey.emit(true);
        console.log('BROKEN SURVEY');
        return false;
      }
    } else {
      console.log('Error loading survey');
      this.surveyError = true;
      this.checkBrokenSurvey.emit(true);
      console.log('BROKEN SURVEY');
      // this.router.navigate(['/auth/404']);
      return false;
    }
  } // end load survey func

  openSurvey() {
    console.log('show survey');
    this.showSurvey = true;
  }

  finishSurvey() {
    try {
      this.sendCommunications();
    } catch (error) {
      console.error(error);
    }

    this.schedulerSubmitSurvey.emit('complete');
  }

  updateCurrentPage(pageNum) {
    this.currentPage = pageNum;
  }

  async submitSurvey(event: string): Promise<void> {
    if (event === 'complete') {
      this.surveySubmitted = true;
      // setup other fields;
      if (!this.survey.apptID && this.apptID) {
        this.survey.apptID = this.apptID;
      }
      if (!this.survey.EMRapptID && this.EMRapptID) {
        this.survey.EMRapptID = this.EMRapptID;
      }
      if (this.survey.EMRapptID) {
        this.cHSService.surveyCompleted(this.EMRapptID);
      }
      this.survey.noteID = this.utilitiesService.getRandomAlphanumerics(32);
      if (!this.editingResponses) {
        this.survey.patientInfo = this.patientInfo;
      }
      this.survey.startTime = this.startTime;
      //// SETUP GRID STUFF
      this.survey = this.specialResponseService.getSpecialResponses(
        this.companyID,
        this.survey
      );
      this.submittingSurvey = true;
      // save patient CPP stuff, if logged in and not a family member
      try {
        // try saving cpp info
        console.log('before update cpp');
        this.updateCPP();
      } catch (err) {
        console.error('error with cpp info', err);
      }

      console.log('survey data before', this.survey);
      this.survey = this.initNoteService.setNote(this.survey, this.companyID, [
        this.userID,
      ]);
      console.log('survey data after', this.survey);

      if (this.lockNote) {
        this.survey.locked = true;
      }

      const user = await this.getUser();
      const isNoteForManualAppointment = this.isManualAppointment(this.apptID);
      const isAuthenticatedRequest = await this.isAuthenticatedRequest(user, this.apptID);

      if (!this.survey.patientInfo || this.survey.patientInfo?.gender === undefined) {
        this.survey.patientInfo = await this.getPatientInfo();
      }
      const patientSid = this.generatePatientId(this.survey.patientInfo);

      let patientUid: string;
      if (isAuthenticatedRequest) {
        try {
          patientUid = isNoteForManualAppointment ?
            this.generateLinkId(this.companyID, this.apptID) :
            this.getFirebaseUserUid(user);
        } catch (error) {
          console.error('error generating patient UID', error);
        }
      }

      const postSurveyBody = {
        apptID: (this.apptID !== undefined) ? this.apptID : this.survey.apptID,
        EMRapptID: this.survey.EMRapptID,
        companyId: this.companyID,
        type: this.survey.type,
        startTime: this.survey.startTime,
        name: this.survey.name,
        patientUid,
        patientSid,
        surveyId: this.survey.id,
        pages: this.survey.pages,
        triggersDictionary: this.survey.triggersDictionary,
        morePDFs: this.survey.morePDFs,
        addedDocs: [],
        familyId: this.familyId,
        patientInfo: await this.generateNotePostBodyPatientInformation(user, this.apptID),
        pdfPath: this.survey.pdfPath,
        note: this.survey.note,
        summary: this.survey.summary,
        rtf: this.survey.rtf,
        integrationResponses: this.survey.integrationResponses,
        pdfID: this.survey.pdfID,
      };

      for (const page of postSurveyBody.pages) {
        for (const element of page.elements) {
          if (element.filter === false) {
            delete element.filter;
          }
        }
      }

      if(
        !isAuthenticatedRequest &&
        !this.isBirthDateValid({
          year: `${parseInt(this.survey.patientInfo.year, 10)}`,
          month: `${parseInt(this.survey.patientInfo.month, 10)}`,
          day: `${parseInt(this.survey.patientInfo.day, 10)}`,
        })
      ) {
        console.error('Invalid birth date fields');
        this.snackbar.open('Invalid birth date', null, { duration: 4000 });
        return;
      }

      let postSurveyRequest: Promise<SpringNote | NoteAndPatientResponse>;
      if (isAuthenticatedRequest) {
        postSurveyRequest = this.submitSurveyService.postAuthenticatedSurveyToSpring(this.companyID, postSurveyBody);
      } else {
        postSurveyRequest = this.submitSurveyService.postUnauthenticatedSurveyToSpring(this.companyID, postSurveyBody);
      }

      await postSurveyRequest
        .then((_response) => {
          this.errorSubmitting = false;
        })
        .catch((error) => {
          if (!isAuthenticatedRequest && error.status === 500) {
            // Indexing patients for unauthorized requests will trigger a false-positive security issue in Spring internally
            console.warn(error);
          } else {
            console.error(error);
            this.errorSubmitting = true;
            this.snackbar.open(error.error, null, { duration: 4000 });
            throw error;
          }
        })
        .finally(() => {
          this.finishSurvey();
          this.submittingSurvey = false;
          this.inProgressSurveyService.removeInProgressSurvey();
        });
    } // end if event complete
  } // end submit survey

  resubmitSurvey() {
    this.resubmitOnCooldown = true;
    this.submitSurvey('complete');
  }

  generateLinkId(companyId: string, appointmentId: string): string {
    if (!companyId || !appointmentId) {
      throw new Error('Not enough information to generate link ID');
    }
    return `${companyId}-${appointmentId}`;
  }

  getFirebaseUserUid(user: firebase.User): string | undefined {
    return user?.uid;
  }

  getPatientInformation(): PatientInfo {
    return this.patientInfo;
  }

  getSurveyPatientInformation(): PatientInfo {
    return this.survey.patientInfo;
  }

  async isAuthenticatedRequest(user: firebase.User, appointmentId: string): Promise<boolean> {
    // manual appointments are not considered authenticated requests
    return await this.loggedInAsPatient(user) && !this.isManualAppointment(appointmentId);
  }

  async getPatientInformationForAppointment(user: firebase.User, appointmentId: string): Promise<PatientInfo> {
    const loggedInAsPatient = await this.loggedInAsPatient(user);
    const isNoteForManualAppointment = this.isManualAppointment(appointmentId);

    return loggedInAsPatient && isNoteForManualAppointment ?
      this.getPatientInformation():
      this.getSurveyPatientInformation();
  }

  generateUnauthenticatedSurveyPatientInformation(patientInfo: PatientInfo): PatientInfo {
    const unauthenticatedPatientInfo = this.deletePatientUidFromPatientInfo(patientInfo);
    const pid = this.generatePatientId(patientInfo);
    const sid = pid;

    return {
      ...unauthenticatedPatientInfo,
      pid,
      sid,
      firstName: patientInfo.first,
      lastName: patientInfo.last,
    } as PatientInfo;
  }

  async generateNotePostBodyPatientInformation(user: firebase.User, appointmentId: string): Promise<PatientInfo | undefined> {
    const isAuthenticatedRequest = await this.isAuthenticatedRequest(user, appointmentId);

    if (isAuthenticatedRequest) {
      return undefined;
    } else {
      const patientInformationToPost = await this.getPatientInformationForAppointment(user, appointmentId);
      return this.generateUnauthenticatedSurveyPatientInformation(patientInformationToPost);
    }
  }

  generatePatientId(patientInfo: PatientInfo): string {
    return this.submitSurveyService.generatePatientID(patientInfo);
  }

  deletePatientUidFromPatientInfo(patientInfo: PatientInfo): PatientInfo {
    delete patientInfo.uid;
    return patientInfo;
  }

  async updateCPP() {
    console.log('Patient logged in');
    console.log(this.patientInfo);
    const cppResponses = this.initNoteService.getCPPResponses(this.survey);
    console.log('cpp responses: ', cppResponses);
    if (this.patientInfo['cpp']) {
      for (const item in cppResponses) {
        if (cppResponses[item]) {
          this.patientInfo['cpp'][item] = cppResponses[item];
        }
      }
    } else {
      this.patientInfo['cpp'] = cppResponses;
    }
    // cleanout undefined fields
    for (const item in this.patientInfo['cpp']) {
      if (this.patientInfo['cpp'][item] === undefined) {
        this.patientInfo['cpp'][item] = null;
      } else if (
        this.patientInfo['cpp'][item] &&
        this.patientInfo['cpp'][item].selectedAnswers
      ) {
        for (const propName in this.patientInfo['cpp'][item].selectedAnswers) {
          if (
            this.patientInfo['cpp'][item].selectedAnswers[propName] ===
            undefined
          ) {
            this.patientInfo['cpp'][item].selectedAnswers[propName] = null;
          }
        }
      }
    }

    // try catch saving cpp data
    if (this.familyId && this.patientInfo.cpp) {
      this.patientUserDataService.updateFamilyMemberCPP(
        this.familyId,
        this.patientInfo.cpp
      );
    } else if (this.patientInfo.uid && this.patientInfo.cpp) {
      this.patientUserDataService.saveCPPData(
        this.patientInfo.uid,
        this.patientInfo.cpp
      );
    } else {
      this.patientUserDataService.saveCPPDataToCompany(
        this.companyID,
        this.submitSurveyService.generatePatientID(this.patientInfo),
        this.patientInfo.cpp
      );
    }
  }

  getOrthoHash() {
    const patientData = this.patientInfo;
    const patientInfo = {
      firstName: patientData.first,
      lastName: patientData.last,
      address: patientData.address || '',
      city: patientData.city || '',
      postalCode: patientData.postal || '',
      birthday: new Date(
        parseInt(patientData.year, 10),
        parseInt(patientData.month, 10) - 1,
        parseInt(patientData.day, 10)
      ).getTime(),
      province: 'Ontario',
      cellPhone: patientData.phone,
      gender: patientData.gender === 'Male' ? 'male' : 'female',
    };

    const hashedPatientID = LZString.compressToEncodedURIComponent(
      JSON.stringify(patientInfo)
    );
    return hashedPatientID;
  }

  async parseCommunicationSubstitutions(
    element
  ): Promise<{ [key: string]: string }> {
    try {
      const orthoHash = this.getOrthoHash();
      const finalSubs = {};
      if (element.question.emailSettings.substitutionGrid) {
        const firstColumnID =
          element.question.emailSettings.substitutionGrid.cols[0].id;
        const secondColumnID =
          element.question.emailSettings.substitutionGrid.cols[1].id;
        // let firstRowID = this.generateRandomID(32);
        element.question.emailSettings.substitutionGrid.rows.forEach((row) => {
          const rowID = row.id;
          finalSubs[
            element.question.emailSettings.substitutions[rowID][firstColumnID]
          ] =
            element.question.emailSettings.substitutions[rowID][secondColumnID];
        });

        for (const i in finalSubs) {
          if (finalSubs[i]) {
            const item = finalSubs[i];
            if (item.includes('%%-summary')) {
              finalSubs[i] = '';
              finalSubs[i] = this.survey.summary;
            } else if (item.includes('%%-orthoHash')) {
              // finalSubs[i] = finalSubs[i].
              finalSubs[i] = finalSubs[i].replace(
                '%%-orthoHash',
                orthoHash || undefined
              );
            }
          }
        }

        return finalSubs;
      }
    } catch (err) {
      console.log(err);
      return undefined;
    }

    // element.question.emailSettings.substitutions;
  }

  async sendCommunications(): Promise<void> {
    console.log('in send communications function');
    if (!this.survey.pages) {
      return;
    }

    // setup email
    const emailFrom = 'info@insighealth.com';
    // images wont work in body of email for some reason

    // iterate through questions to find shareMedicalNote questions
    try {
      // console.log("looping through elements: ")
      for (const page of this.survey.pages) {
        if (
          !page.elements ||
          !page.elements.length ||
          page.elements.length < 1
        ) {
          return;
        }

        for (const element of page.elements) {
          if (
            element.question &&
            element.question.type === 'sendCommunication' &&
            !!element.question.emailSettings &&
            !!element.question.emailSettings.type &&
            !!element.question.emailSettings.emailTo
          ) {
            // console.log("element: ", element);

            let emailTo;
            if (element.question.emailSettings.emailTo === 'custom') {
              emailTo = element.question.emailSettings.to;
            } else if (element.question.emailSettings.emailTo === 'patient') {
              emailTo = this.survey.patientInfo.email || undefined;
            } else if (element.question.emailSettings.emailTo === 'clinic') {
              if (this.locationID) {
                try {
                  const locationData = (
                    await this.companyLocationService.queryLocationByID(
                      this.locationID
                    )
                  ).docs[0].data();
                  if (locationData.emails) {
                    emailTo = locationData.emails.map((email) => ({ email }));
                  }
                } catch (err) {
                  console.log('error getting location data: ', err);
                }
              } else if (this.clinicEmail) {
                emailTo = this.clinicEmail || undefined;
              }
            }

            if (element.question.emailSettings.type === 'template') {
              if (emailTo) {
                try {
                  const subject = element.question.emailSettings.subject;
                  const templateID = element.question.emailSettings.templateID;
                  let substitutions = await this.parseCommunicationSubstitutions(
                    element
                  );
                  console.log('substitutions are: ', substitutions);
                  if (substitutions) {
                    substitutions = {
                      ...substitutions,
                      ...this.survey.patientInfo,
                    };
                    console.log(
                      emailTo,
                      emailFrom,
                      subject,
                      templateID,
                      substitutions
                    );
                  }

                  // send whole note or just contact info
                } catch (error) {
                  console.log('----Error sending email');
                  console.log(error);
                }
              }
            } else if (element.question.emailSettings.type === 'custom') {
              if (emailTo) {
                try {
                  const subject = element.question.emailSettings.subject;
                  let message = element.question.emailSettings.body;
                  if (element.question.emailSettings.sendQuestionnaireData) {
                    message += '<br />----------------------<br />';
                    message += this.survey.note;
                    message += '<br />----------------------<br />';
                    message += this.survey.summary;
                  }
                  // send whole note or just contact info
                  this.sendEmailService.sendEmail(
                    emailTo,
                    emailFrom,
                    subject,
                    message
                  );
                } catch (error) {
                  console.log('----Error sending email');
                  console.log(error);
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log('----Error iterating through survey');
      console.log(error);
    }
  }

  async switchLanguage(language: any) {
    console.log('Launch survey language changed');
    console.log(language);
    this.translateService.changeLanguage(language);
  }

  // decrypt chs patient info and set into pt check in fields
  async autopopulateCHSPtInfo(enc, iv) {
    try {
      const response: any = await this.cHSService.decodeCHSMessage(enc, iv);
      console.log(response);
      if (response.body) {
        const ptArray: string[] = response.body.split('&');
        const ptDict = ptArray.reduce((dict, item) => {
          dict[item.split('=')[0]] = item.split('=')[1];
          return dict;
        }, {});
        this.autopopulatePtInfo(ptDict);
      }
    } catch (err) {
      console.log(err);
    }
  } // end func

  autopopulateLinkPtInfo(ptInfoLZString) {
    const ptDict = JSON.parse(
      LZString.decompressFromEncodedURIComponent(ptInfoLZString)
    );
    console.log(ptDict);
    this.autopopulatePtInfo(ptDict);
  }

  autopopulatePtInfo(ptDict) {
    if (ptDict['appointmentId']) {
      this.EMRapptID = ptDict['appointmentId'];
    }
    if (ptDict['patientBirthDate']) {
      this.patientInfo['year'] = ptDict['patientBirthDate'].split('/')[0];
      this.patientInfo['month'] = ptDict['patientBirthDate'].split('/')[1];
      this.patientInfo['day'] = ptDict['patientBirthDate'].split('/')[2];
    }
    if (ptDict['patientEmail']) {
      this.patientInfo['email'] = ptDict['patientEmail'];
    }
    if (ptDict['patientFirstName']) {
      this.patientInfo['first'] = ptDict['patientFirstName'];
    }
    if (ptDict['patientLastName']) {
      this.patientInfo['last'] = ptDict['patientLastName'];
    }
    if (ptDict['patientGender']) {
      ptDict['patientGender'] === '0'
        ? (this.patientInfo['gender'] = 'Male')
        : (this.patientInfo['gender'] = 'Female');
    }
    if (ptDict['patientHealthCard']) {
      this.patientInfo['healthCardNumber'] = ptDict['patientHealthCard'];
    }
    if (ptDict['patientPhone']) {
      this.patientInfo['phone'] = ptDict['patientPhone'];
    }
    if (ptDict['patientEmail']) {
      this.patientInfo['email'] = ptDict['patientEmail'];
    }
    // additional fields
    if (ptDict['address']) {
      this.patientInfo['address'] = ptDict['address'];
    }
    if (ptDict['postalCode']) {
      this.patientInfo['postalCode'] = ptDict['postalCode'];
    }
    if (ptDict['province']) {
      this.patientInfo['province'] = ptDict['province'];
    }
    if (ptDict['city']) {
      this.patientInfo['city'] = ptDict['city'];
    }
    if (ptDict['country']) {
      this.patientInfo['country'] = ptDict['country'];
    }

    console.log(this.patientInfo);
    console.log(this.apptID);
    if (
      this.patientInfo['phone'] &&
      this.patientInfo['first'] &&
      this.patientInfo['last'] &&
      this.patientInfo['gender'] &&
      this.patientInfo['year'] &&
      this.patientInfo['month'] &&
      this.patientInfo['day']
    ) {
      this.patientInfoAutopopulate = true;
    }
  } // end func

  setPtCookie() {
    this.cookieService.set(
      'patientInfo',
      LZString.compressToUTF16(JSON.stringify(this.patientInfo)),
      new Date(new Date().getTime() + this.EXPIRECOOKIE),
      undefined,
      undefined,
      true,
      'Strict'
    );
  }

  reloadPtInfoFromCookie(): void {
    // shouldn't load in for any of these situations
    if (
      !!this.patientInfoFromScheduler ||
      this.preview ||
      this.patientInfoAutopopulate ||
      this.quickView ||
      this.schedulerLogin ||
      this.checkInMode === 'true'
    ) {
      return;
    }
    try {
      let savedPtInfo: any = this.cookieService.get('patientInfo');
      this.cookieService.delete('patientInfo');
      if (savedPtInfo) {
        savedPtInfo = JSON.parse(LZString.decompressFromUTF16(savedPtInfo));
        if (!!savedPtInfo && !!savedPtInfo.first && !!savedPtInfo.day) {
          this.patientInfo = this.patientInfoFromCookie = savedPtInfo;
        }
      }

      if (savedPtInfo) {
        const [inProgressSurvey, currentPage] = this.inProgressSurveyService.loadInProgressSurvey({
          doctorId: this.userID,
          surveyId: this.surveyID,
        });
        if (inProgressSurvey !== null && currentPage !== null) {
          this.tempSurveyLoaded = true;
          this.survey = inProgressSurvey;
          this.currentPage = currentPage;
          this.inProgressSurveyService.removeInProgressSurvey();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async printResults(): Promise<void> {
    this.snackbar.open('Processing ...', null, { duration: 4000 });
    const pdf = await this.notesService.getPdfFromHtml(this.survey.note);
    const pdfFile = new Blob([pdf], {
      type: 'application/pdf',
    });
    const pdfUrl = URL.createObjectURL(pdfFile);
    printJS(pdfUrl);
  }

  trackAdClick(adID: string, url: string) {
    console.log(adID);
    this.googleAnalyticsService.startAnalytics();
    this.googleAnalyticsService.googleEvent(adID, { url });
    window.open(url, '_blank');
  } // end func

  isBirthDateValid(
    { year, month, day }: { year: string, month: string, day: string }
  ): boolean {
    const isDateValid = this.isDateValid({ year, month, day });
    const isYearValid = year.match(YEAR_REGEX) !== null;
    const isMonthValid = month.match(MONTH_REGEX) !== null;
    const isDayValid = day.match(DAY_REGEX) !== null;

    return isYearValid && isMonthValid && isDayValid && isDateValid;
  }

  isDateValid({ year, month, day }: { year: string, month: string, day: string }): boolean {
    const paddedDay = this.padNumberToTwoDigits(day);
    const paddedMonth = this.padNumberToTwoDigits(month);

    const combinedBirthDateFields = `${year}-${paddedMonth}-${paddedDay}`;
    return this.dateAndTimeService.isDateValid(combinedBirthDateFields, 'YYYY-MM-DD');
  }

  padNumberToTwoDigits(number: string | number): string {
    if (typeof number === 'number') {
      number = `${number}`;
    }

    if (number.length === 1) {
      return `0${number}`;
    } else {
      return number;
    }
  }

  async loggedInAsPatient(user: firebase.User | null): Promise<boolean> {
    if (user === null) {
      return false;
    } else {
      return !await this.firebaseAuthService.checkIfUserIsDoctor(user);
    }
  }

  isManualAppointment(apptId: string): boolean {
    return this.regexService.getManualAppointmentLinkRegex().test(apptId);
  }

  isLoggedInWithUid(user: firebase.User | null, uid: string): boolean {
    if (user === null || uid === undefined) {
      return false;
    } else {
      return user.uid === uid;
    }
  }

  handlePatientInfoChanged(patientInfo: PatientInfo): void {
    this.patientInfo = patientInfo;
  }

  navigateToDashboardPage(): void {
    this.router.navigate(['app/virtual/dashboard']);
  }
} // end component
