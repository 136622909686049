import { Directive, ElementRef, inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[insigCorePhoneNumberInput]',
})
export class PhoneNumberInputDirective implements OnDestroy, OnInit {
  private readonly elementRef = inject(ElementRef);
  private readonly observer = new MutationObserver(this.onMutation.bind(this));
  private readonly onMutated$ = new Subject<void>();

  ngOnInit(): void {
    this.onMutation();
    this.observer.observe(this.elementRef.nativeElement, { childList: true, subtree: true });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  onMutation(): void {
    const requiredAsteriskElement = this.elementRef.nativeElement.querySelector('.required') as HTMLElement;
    if (requiredAsteriskElement) {
      requiredAsteriskElement.style.display = 'none';
    }

    const inputElements = this.elementRef.nativeElement.querySelectorAll('#inputElement') as HTMLInputElement[];
    inputElements.forEach((inputElement) => {
      inputElement.style.backgroundColor = 'transparent';
      inputElement.style.border = '0px';
      inputElement.style.width = '100%';
    });

    this.onMutated$.next();
  }

  getOnMutated(): Observable<void> {
    return this.onMutated$.asObservable();
  }
}
